import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';

// keyframes
export const kSlideIn = keyframes`
  50% {
    opacity: 1;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// css
export const slideIn = css`
  animation: ${kSlideIn} 0.8s ease forwards;
`;

export const FlowerIntroZoomIn = css`
  animation: zoomIn 2.5s ease-in-out;

  @keyframes zoomIn {
    0% {
      transform: scale(0);
    }
    20% {
      transform: scale(0.275);
    }
    40% {
      transform: scale(0.275);
    }
    70% {
      transform: scale(0.75);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const EclipseBlur = styled.div<{
  width?: string;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  translateX?: string;
  translateY?: string;
  maxWidth?: string;
  zIndex?: number;
}>`
  position: absolute;
  top: ${({ top }) => (top ? `${top}` : 'initial')};
  left: ${({ left }) => (left ? `${left}` : 'initial')};
  right: ${({ right }) => (right ? `${right}` : 'initial')};
  bottom: ${({ bottom }) => (bottom ? `${bottom}` : 'initial')};
  z-index: ${({ zIndex }) => (zIndex ? `${zIndex}` : 'initial')};

  width: ${({ width }) => (width ? `${width}` : '587px')};
  height: ${({ width }) => (width ? `${width}` : '587px')};

  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}` : 'initial')};
  max-height: ${({ maxWidth }) => (maxWidth ? `${maxWidth}` : 'initial')};

  background-image: url('/images/blur.webp');
  background-repeat: no-repeat;
  background-size: contain;
  transform: ${({ translateY, translateX }) =>
    `
    ${translateY ? `translateY(${translateY})` : ''}
    ${translateX ? `translateX(${translateX})` : ''}
  `};
`;

export const RectangleBlur = styled.div<{
  width?: string;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  rotate?: number;
  translateX?: string;
  translateY?: string;
  maxWidth?: string;
  opacity?: number;
  zIndex?: number;
}>`
  position: absolute;

  top: ${({ top }) => (top ? `${top}` : 'initial')};
  left: ${({ left }) => (left ? `${left}` : 'initial')};
  right: ${({ right }) => (right ? `${right}` : 'initial')};
  bottom: ${({ bottom }) => (bottom ? `${bottom}` : 'initial')};
  z-index: ${({ zIndex }) => (zIndex ? `${zIndex}` : 'initial')};

  width: ${({ width }) => (width ? `${width}` : '587px')};
  height: ${({ width }) => (width ? `${width}` : '587px')};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}` : 'initial')};
  max-height: ${({ maxWidth }) => (maxWidth ? `${maxWidth}` : 'initial')};

  background: linear-gradient(180deg, #a336d9 0%, rgba(163, 54, 217, 0) 100%);
  opacity: ${({ opacity }) => opacity || 1};
  border-radius: 31px;
  transform: ${({ rotate, translateY, translateX }) =>
    `
    ${rotate ? `rotate(${rotate}deg)` : ''}
    ${translateY ? `translateY(${translateY})` : ''}
    ${translateX ? `translateX(${translateX})` : ''}
  `};
`;

export const EclipseLinearBlur = styled.div<{
  width?: string;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  rotate?: number;
  translateX?: string;
  translateY?: string;
  maxWidth?: string;
  index?: number;
}>`
  position: absolute;
  top: ${({ top }) => (top ? `${top}` : 'initial')};
  left: ${({ left }) => (left ? `${left}` : 'initial')};
  right: ${({ right }) => (right ? `${right}` : 'initial')};
  bottom: ${({ bottom }) => (bottom ? `${bottom}` : 'initial')};

  width: ${({ width }) => (width ? `${width}` : '587px')};
  height: ${({ width }) => (width ? `${width}` : '587px')};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}` : 'initial')};
  max-height: ${({ maxWidth }) => (maxWidth ? `${maxWidth}` : 'initial')};

  background: linear-gradient(180deg, #a336d9 0%, rgba(163, 54, 217, 0) 100%);
  border-radius: 300px;
  transform: ${({ rotate, translateY, translateX }) =>
    `
    ${rotate ? `rotate(${rotate}deg)` : ''}
    ${translateY ? `translateY(${translateY})` : ''}
    ${translateX ? `translateX(${translateX})` : ''}
  `};
  z-index: ${({ index }) => (index ? `${index}` : `1`)};
`;
