import { Router, useRouter } from 'next/router';
import Head from 'next/head';

import { ISeo } from 'types/global.interface';

const Seo = ({ seo = undefined }: { seo?: ISeo | undefined }): JSX.Element => {
  const router = useRouter() as Router;
  const canonicalUrl = (
    `${process.env.DOMAIN_URL}` + (router.asPath === '/' ? '' : router.asPath)
  ).split('?')[0] as string;

  return (
    <Head>
      {seo?.favicon?.url && (
        <link rel="shortcut icon" href={seo.favicon.url} type="image/x-icon" />
      )}
      {process.env.STAGE !== 'production' && canonicalUrl && (
        <link rel="canonical" href={canonicalUrl} key="canonical" />
      )}
      {seo?.defaultSeo?.metaTitle && (
        <>
          <title>{`${seo.siteName} | ${seo.defaultSeo.metaTitle}`}</title>
          <meta
            property="og:title"
            content={`${seo.siteName} | ${seo.defaultSeo.metaTitle}`}
            key="title"
          />
          <meta
            name="twitter:title"
            content={`${seo.siteName} | ${seo.defaultSeo.metaTitle}`}
            key="twitter-title"
          />
        </>
      )}
      {seo?.defaultSeo?.metaDescription && (
        <>
          <meta
            name="description"
            content={seo.defaultSeo.metaDescription}
            key="description"
          />
          <meta
            property="og:description"
            content={seo.defaultSeo.metaDescription}
            key="og-description"
          />
          <meta
            name="twitter:description"
            content={seo.defaultSeo.metaDescription}
            key="twitter-description"
          />
        </>
      )}
      {seo?.defaultSeo?.shareImage && (
        <>
          <meta
            property="og:image"
            content={seo.defaultSeo.shareImage.url}
            key="og-image"
          />
          <meta
            name="twitter:image"
            content={seo.defaultSeo.shareImage.url}
            key="twitter-image"
          />
          <meta
            name="image"
            content={seo.defaultSeo.shareImage.url}
            key="image"
          />
        </>
      )}
      <meta name="viewport" content="width=device-width" />
      <meta name="twitter:card" content="summary_large_image" />
    </Head>
  );
};

export default Seo;
