import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

// style
import 'styles/global.scss';

// component
import Header from 'layout/Header';
import Footer from 'layout/Footer';
import IntroFlower from 'components/IntroFlower/IntroFlower';
import FlowerBanner from 'components/Banner/FlowerBanner';

import { ELEM_APP_MODAL_ID } from 'constant/appConstant';
import Axios from 'config/Axios';
import Seo from 'components/_seo';
import { ISeo } from 'types/global.interface';

function MyApp({
  Component,
  pageProps,
  seoData,
}: AppProps & { seoData: ISeo }) {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  const [step, setStep] = useState(1);

  const isFirstLoad = useRef(false);

  useEffect(() => {
    const handleStart = () => {
      // setPageLoading(true);
    };

    const handleComplete = () => {
      // setPageLoading(false);
    };

    if (!isFirstLoad.current) {
      setIsLoading(true);

      if (router.pathname !== '/blog/[id]' && router.pathname !== '/blog')
        document.querySelector('body')?.classList.add('stop-scrolling');

      setTimeout(() => {
        setStep(2);
        if (router.pathname !== '/') {
          setIsLoading(false);
          isFirstLoad.current = true;
          document.querySelector('body')?.classList.remove('stop-scrolling');
        }
      }, 4_000);

      setTimeout(() => {
        document.querySelector('body')?.classList.remove('stop-scrolling');
      }, 7_000);

      setTimeout(() => {
        isFirstLoad.current = true;
        setStep(3);
        setIsLoading(false);
      }, 8_000);
    } else {
      setIsLoading(false);
    }

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);
  }, [router]);

  const animationCondition =
    isLoading &&
    router.pathname !== '/blog/[id]' &&
    router.pathname !== '/blog';
  return (
    <>
      <Seo seo={seoData} />

      {animationCondition && step === 1 && <IntroFlower />}

      {animationCondition && router.pathname === '/' && step === 2 && (
        <FlowerBanner />
      )}

      <>
        <Header />
        <Component {...pageProps} />
        <Footer />
      </>

      <div id={ELEM_APP_MODAL_ID} />
    </>
  );
}

MyApp.getInitialProps = async () => {
  try {
    const res = await Axios.get('global?populate=*');
    return {
      seoData: res.data?.seo || {},
    };
  } catch (error) {
    console.error('Error App Init Props: ', error);
    return {
      seoData: {},
    };
  }
};

export default MyApp;
