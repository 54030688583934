function LogoSVG(): JSX.Element {
  return (
    <svg
      className="logo_svg"
      width="166"
      height="46"
      viewBox="0 0 166 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9255 19.0851C22.9214 19.0768 22.9214 19.0727 22.9172 19.0644C22.9131 19.0685 22.9048 19.0685 22.9006 19.0727C22.9089 19.0768 22.9172 19.081 22.9255 19.0851Z"
        fill="#EC2227"
      />
      <path
        d="M26.7596 17.0956C27 15.8065 27.0912 14.4884 27.0249 13.1828C26.9835 12.333 26.8757 11.4792 26.7016 10.6419C26.1213 7.81508 24.8281 5.1872 22.9546 3.04013L22.8966 2.97381L22.8427 3.04427C21.0521 5.09601 19.7879 7.5954 19.1744 10.2855C18.6107 9.85853 18.0263 9.46061 17.4377 9.1083L17.4128 9.09172C18.2667 5.94572 19.9164 3.08158 22.1919 0.806016L22.9007 0.101379L23.6095 0.810161C25.9638 3.16448 27.6425 6.1281 28.4674 9.38601C28.6539 10.1238 28.7989 10.8823 28.8943 11.6408C29.1057 13.2947 29.0932 14.9734 28.8611 16.6355L28.8238 16.6438C28.1358 16.7557 27.4436 16.909 26.7596 17.0956Z"
        fill="url(#paint0_radial_2017_8853)"
      />
      <path
        d="M31.5222 22.7202C30.9585 22.314 30.3616 21.9327 29.744 21.5804C30.83 20.8384 31.8248 19.968 32.7035 18.9981C33.2755 18.3639 33.806 17.68 34.2744 16.9671C35.8578 14.563 36.7987 11.7942 36.9893 8.95493L36.9935 8.86789L36.9064 8.87203C34.2371 9.05441 31.6134 9.90412 29.3088 11.3258C29.2093 10.6253 29.0767 9.93314 28.9067 9.26995L28.8984 9.24508C31.6963 7.66586 34.8505 6.83273 38.0256 6.83273H39.0286V7.8358C39.0286 11.1517 38.125 14.4304 36.4173 17.3111C36.0277 17.966 35.5966 18.6043 35.1241 19.2136C34.1003 20.54 32.8983 21.7296 31.5512 22.7451L31.5222 22.7202Z"
        fill="url(#paint1_radial_2017_8853)"
      />
      <path
        d="M22.1919 45.194C19.8334 42.8355 18.1506 39.8636 17.3258 36.5933C17.1392 35.8555 16.9942 35.0928 16.8988 34.3302C16.6916 32.6846 16.704 31.0142 16.9361 29.3645L16.9734 29.3562C17.6574 29.2485 18.3496 29.0951 19.0335 28.9044C18.7972 30.1769 18.706 31.4702 18.764 32.7551C18.8055 33.6089 18.9091 34.4711 19.0791 35.3125C19.6552 38.1518 20.9526 40.7962 22.8344 42.9557L22.8924 43.0221L22.9504 42.9557C24.712 40.9372 25.9679 38.4792 26.5897 35.8389C27.1575 36.2617 27.7419 36.6555 28.3305 37.0036L28.3513 37.0161C27.4933 40.1165 25.8477 42.9392 23.6012 45.1898L22.8924 45.8986L22.1919 45.194Z"
        fill="url(#paint2_radial_2017_8853)"
      />
      <path
        d="M6.64429 39.2212V38.2222C6.64429 34.8773 7.56446 31.5738 9.30118 28.6765C9.69081 28.0216 10.1302 27.3833 10.6027 26.7781C11.614 25.4766 12.7995 24.3119 14.1259 23.3171L14.159 23.342C14.7227 23.7482 15.3196 24.1336 15.9372 24.4777C14.872 25.2072 13.8938 26.0569 13.0275 27.0061C12.4513 27.6361 11.9208 28.3159 11.4483 29.0288C9.84002 31.4494 8.88669 34.239 8.69188 37.1031L8.68773 37.1901L8.77478 37.186C11.4938 36.9995 14.159 36.1249 16.4968 34.6535C16.5962 35.3539 16.7289 36.0461 16.8988 36.7093L16.9071 36.7384C14.0803 38.3632 10.8804 39.2212 7.65979 39.2212H6.64429Z"
        fill="url(#paint3_radial_2017_8853)"
      />
      <path
        d="M9.08979 17.0127C7.48985 14.2024 6.64429 11.0315 6.64429 7.8358V6.83273H7.64321C10.9923 6.83273 14.3 7.75291 17.2014 9.49792C17.8563 9.89169 18.4946 10.331 19.1039 10.8036C20.3971 11.8149 21.5577 12.9962 22.5525 14.3143L22.5276 14.3475C22.1214 14.9112 21.7401 15.5081 21.3919 16.1256C20.6707 15.0728 19.8293 14.1029 18.8967 13.2449C18.2666 12.6646 17.5827 12.1299 16.8657 11.6533C14.4409 10.0368 11.6431 9.07514 8.76649 8.88032L8.67944 8.87618L8.68359 8.96322C8.86597 11.6491 9.72396 14.2894 11.1664 16.6065C10.4618 16.7059 9.7737 16.8469 9.11466 17.0127H9.08979Z"
        fill="url(#paint4_radial_2017_8853)"
      />
      <path
        d="M8.895 28.4651C5.78631 27.6071 2.95947 25.9615 0.704637 23.7109L0 23.0021L0.708782 22.2933C3.04652 19.9556 5.99356 18.281 9.23074 17.4479C9.96439 17.2572 10.7229 17.1121 11.4856 17.0127C12.2856 16.909 13.098 16.8551 13.9062 16.8551C14.7808 16.8551 15.6678 16.9173 16.5382 17.0417L16.5465 17.079C16.6584 17.767 16.8077 18.4592 16.9983 19.139C15.9828 18.9483 14.9466 18.853 13.9145 18.853C13.6202 18.853 13.3259 18.8613 13.0316 18.8779C12.1819 18.9235 11.3281 19.0354 10.4949 19.2053C7.68883 19.7898 5.07753 21.083 2.9429 22.944L2.87658 23.0021L2.9429 23.0601C4.96562 24.8258 7.43599 26.0859 10.0846 26.7035C9.65767 27.2672 9.25976 27.8516 8.91158 28.4361L8.895 28.4651Z"
        fill="url(#paint5_radial_2017_8853)"
      />
      <path
        d="M38.0296 39.2212C34.7179 39.2212 31.4475 38.3217 28.5668 36.6182C27.916 36.2327 27.2736 35.7975 26.6684 35.3291C25.3338 34.3011 24.14 33.095 23.1245 31.7437L23.1494 31.7106C23.5556 31.1469 23.9369 30.55 24.2892 29.9324C25.0395 31.0308 25.9182 32.0339 26.9047 32.9209C27.5389 33.4887 28.2186 34.0151 28.9274 34.4794C31.3232 36.0544 34.0878 36.987 36.9147 37.1777L37.0017 37.1819L36.9976 37.0948C36.8152 34.3923 35.9489 31.7396 34.494 29.4184C35.2111 29.3189 35.9033 29.1905 36.5541 29.0288L36.5831 29.0205C38.1872 31.8349 39.0369 35.0141 39.0369 38.2181V39.217H38.0296V39.2212Z"
        fill="url(#paint6_radial_2017_8853)"
      />
      <path
        d="M31.8745 29.1531C31.0248 29.1531 30.1461 29.091 29.2632 28.9666L29.2549 28.9293C29.143 28.2413 28.9938 27.549 28.8031 26.8651C29.8228 27.0558 30.859 27.1511 31.8869 27.1511C32.1232 27.1511 32.3636 27.147 32.5999 27.1346C33.4537 27.0973 34.3158 26.9936 35.1614 26.8278C38.0214 26.26 40.6824 24.9543 42.8544 23.0601L42.9207 23.0021L42.8544 22.944C40.8109 21.1617 38.3157 19.8975 35.6381 19.2799C36.065 18.7121 36.4546 18.1276 36.8028 17.5391L36.8152 17.5142C39.9529 18.368 42.8129 20.0177 45.0844 22.2891L45.7931 22.9979L45.0844 23.7067C42.7135 26.0817 39.7167 27.7687 36.4298 28.5853C35.7044 28.7635 34.9417 28.9044 34.1625 28.9998C33.3832 29.1034 32.6123 29.1531 31.8745 29.1531Z"
        fill="url(#paint7_radial_2017_8853)"
      />
      <path
        d="M21.9515 20.8716C21.7774 20.7763 21.595 20.6809 21.4002 20.5814C21.1059 20.4322 20.795 20.2913 20.4469 20.1421L20.3515 19.8975C20.3391 19.8685 20.3308 19.8395 20.3184 19.8146C20.3101 19.7898 20.3018 19.769 20.2935 19.7442C20.0448 19.0893 19.8334 18.4095 19.6718 17.7214C20.1111 17.8624 20.48 17.9909 20.8282 18.1318L20.8945 18.1608L20.9235 18.0945C21.1391 17.5888 21.396 17.0707 21.6779 16.5567C22.0468 15.877 22.4654 15.2138 22.9214 14.5837C23.8871 13.2449 25.0353 12.0346 26.3326 10.9859C26.4694 11.7196 26.5565 12.4574 26.5938 13.1786L26.6062 13.3983C25.628 14.3226 24.77 15.3588 24.0488 16.478C23.568 17.2241 23.1452 18.0157 22.7929 18.824C22.7846 18.8406 22.7763 18.8613 22.768 18.8779C22.7556 18.9027 22.7473 18.9235 22.7349 18.9483C22.7224 18.9815 22.7058 19.0147 22.6934 19.0478L22.6685 19.11C22.511 19.4872 22.3742 19.8644 22.254 20.225C22.1877 20.4281 22.1255 20.6312 22.0675 20.8218L22.0385 20.9213L21.9515 20.8716Z"
        fill="url(#paint8_linear_2017_8853)"
      />
      <path
        d="M19.5101 34.9146C19.3734 34.1768 19.2863 33.4348 19.2532 32.7095L19.2407 32.4815C20.165 31.5862 20.9857 30.5914 21.6738 29.5179C22.1546 28.7718 22.5774 27.9801 22.9297 27.1719L22.9587 27.1055C22.9836 27.0517 23.0043 27.0019 23.025 26.948L23.0416 26.9024C23.2157 26.4797 23.3774 26.0362 23.5224 25.5885L23.5432 25.518C23.5805 25.4061 23.6178 25.2859 23.6509 25.1699L23.709 24.9668L23.8955 25.0704C24.0032 25.1326 24.1152 25.1864 24.2229 25.2445L24.281 25.2735C24.6333 25.4559 25.0105 25.6341 25.4001 25.7999L25.4954 26.0403C25.769 26.7366 25.9969 27.4703 26.1752 28.2205C25.7192 28.0755 25.3089 27.9262 24.9276 27.7729L24.8571 27.7439L24.8281 27.8102C24.596 28.3532 24.3307 28.9003 24.0405 29.4391C23.6716 30.1189 23.253 30.7821 22.7971 31.4121C21.8686 32.7053 20.7578 33.8825 19.5101 34.9146Z"
        fill="url(#paint9_linear_2017_8853)"
      />
      <path
        d="M17.6823 26.2227C17.8233 25.7833 17.9517 25.4144 18.0927 25.0662L18.1217 24.9958L18.0554 24.9668C17.5124 24.7346 16.9653 24.4694 16.4264 24.1792C15.7466 23.8103 15.0835 23.3917 14.4534 22.9358C13.1312 21.9824 11.9333 20.8509 10.8929 19.5701C11.6266 19.4292 12.3644 19.3421 13.0856 19.3007L13.3053 19.2882C14.2213 20.2498 15.2451 21.0954 16.3477 21.8083C17.0937 22.2891 17.8854 22.7119 18.6937 23.0642L18.7642 23.0933C18.8139 23.114 18.8595 23.1347 18.9092 23.1554C18.9382 23.1679 18.9714 23.1803 19.0046 23.1927C19.0294 23.201 19.0502 23.2093 19.075 23.2218C19.4522 23.3751 19.8294 23.5119 20.2024 23.6321C20.3848 23.6901 20.5423 23.7399 20.6998 23.7855L20.8946 23.8393L20.8408 23.9388C20.7496 24.1046 20.6625 24.2746 20.5879 24.4196C20.4263 24.7388 20.2688 25.0745 20.1113 25.4434L19.8667 25.5388C19.8418 25.5471 19.817 25.5595 19.7921 25.5678C19.7714 25.5761 19.7465 25.5844 19.7258 25.5926C19.0584 25.8496 18.3745 26.0569 17.6823 26.2227Z"
        fill="url(#paint10_linear_2017_8853)"
      />
      <path
        d="M13.9518 29.12C13.3342 29.12 12.6793 29.0827 12.0078 29.0122C12.4347 28.3946 12.8948 27.8143 13.3839 27.2755L13.5373 27.1097C13.6741 27.1138 13.815 27.1138 13.9518 27.1138C15.1414 27.1138 16.3268 26.9895 17.4791 26.7366C18.3495 26.5501 19.2034 26.289 20.0241 25.9657C20.0407 25.9574 20.0573 25.9533 20.0738 25.945C20.0946 25.9367 20.1153 25.9284 20.136 25.9201L20.2438 25.8786C20.6417 25.717 21.0355 25.5346 21.4168 25.344C21.6116 25.2445 21.7857 25.1533 21.9515 25.0621L22.0302 25.0206L22.0717 25.1657C22.1214 25.3357 22.1795 25.518 22.2458 25.7294C22.3535 26.061 22.4779 26.3926 22.6188 26.7449L22.5152 26.9853C22.511 26.9978 22.5028 27.0144 22.4945 27.0268C22.4903 27.0392 22.482 27.0517 22.4779 27.0641C22.1877 27.7231 21.8437 28.3739 21.4582 28.9998C21.2427 28.5811 21.0603 28.2039 20.9111 27.8475L20.8821 27.7812L20.8158 27.8102C20.2977 28.0174 19.7588 28.1998 19.2117 28.3615C18.4697 28.5811 17.7029 28.7552 16.9361 28.8754C15.9455 29.0371 14.9424 29.12 13.9518 29.12Z"
        fill="url(#paint11_linear_2017_8853)"
      />
      <path
        d="M32.3843 26.6537C31.4807 25.7128 30.4694 24.8797 29.3834 24.1792C28.6373 23.6984 27.8456 23.2756 27.0374 22.9233C27.0208 22.915 27.0001 22.9067 26.9835 22.8984C26.9586 22.886 26.9337 22.8736 26.9047 22.8653L26.8591 22.8446C26.4446 22.6705 26.0053 22.5088 25.5535 22.3596C25.4001 22.3099 25.2219 22.2518 25.0354 22.198L24.9235 22.1648L25.0188 21.9907C25.1058 21.8332 25.1887 21.6715 25.2592 21.5306C25.4291 21.199 25.5949 20.8467 25.7483 20.4861L25.9929 20.3908L26.0011 20.3866C26.6933 20.1172 27.4187 19.8892 28.1689 19.711C28.0197 20.1752 27.8746 20.5814 27.7254 20.9545L27.6964 21.0208L27.7627 21.0498C28.2684 21.2653 28.7865 21.5182 29.3005 21.8042C29.9761 22.1731 30.6435 22.5917 31.2735 23.0477C32.5874 23.9968 33.777 25.1201 34.8132 26.3885C34.0754 26.5211 33.3335 26.604 32.604 26.6372L32.3843 26.6537Z"
        fill="url(#paint12_linear_2017_8853)"
      />
      <path
        d="M18.8718 22.6456C18.1879 22.3472 17.5081 21.9907 16.8532 21.5886C17.2926 21.3648 17.6905 21.1742 18.0677 21.0166L18.134 20.9876L18.105 20.9172C17.8936 20.3908 17.6988 19.8312 17.5289 19.2509C17.3092 18.509 17.1392 17.7422 17.0149 16.9753C16.7579 15.392 16.7123 13.7755 16.8698 12.159C17.4874 12.5859 18.0718 13.0543 18.6107 13.5434L18.7806 13.7009C18.7599 14.9899 18.8843 16.2749 19.1537 17.5225C19.3402 18.3929 19.6013 19.2468 19.9246 20.0716C19.9329 20.0965 19.9454 20.1213 19.9536 20.1462C19.9661 20.1835 19.9827 20.2208 19.9951 20.254L20.02 20.3162C20.1899 20.7306 20.3889 21.1576 20.6085 21.5804L20.621 21.6052C20.6873 21.7337 20.7578 21.8664 20.8282 21.999L20.907 22.1441L20.6873 22.2062C20.5588 22.2435 20.4262 22.285 20.3018 22.3264L20.2521 22.343C19.8749 22.4632 19.4894 22.6042 19.1081 22.7575L18.8718 22.6456Z"
        fill="url(#paint13_linear_2017_8853)"
      />
      <path
        d="M23.6592 20.8218C23.6177 20.6809 23.5721 20.54 23.5307 20.4073L23.5183 20.3742C23.4022 20.0053 23.2654 19.6364 23.1079 19.2468L23.2115 19.0022C23.2157 18.9898 23.2198 18.9815 23.224 18.9691C23.2281 18.9566 23.2364 18.9483 23.2405 18.9359C23.5348 18.2727 23.8789 17.6137 24.2685 16.9836C24.484 17.4023 24.6664 17.7795 24.8156 18.1359L24.8446 18.2022L24.9151 18.1732C25.4705 17.9494 26.0508 17.7463 26.6435 17.5722C27.3855 17.3525 28.1523 17.1826 28.9191 17.0583C29.8931 16.9007 30.8921 16.822 31.8827 16.822C32.5044 16.822 33.1345 16.8552 33.7562 16.9132C33.3334 17.5308 32.8733 18.1152 32.3884 18.654L32.2392 18.8198C32.1273 18.8157 32.0153 18.8157 31.9034 18.8157C30.7138 18.8157 29.5284 18.9442 28.3761 19.1929C27.5057 19.3794 26.6518 19.6405 25.8311 19.9638L25.7731 19.9846C25.7109 20.0094 25.6487 20.0343 25.5907 20.0592C25.57 20.0675 25.5493 20.0758 25.5285 20.084L25.4871 20.1006C25.0892 20.2664 24.6913 20.4529 24.3058 20.6519L24.2685 20.6685C24.1483 20.7307 24.0239 20.797 23.8996 20.8633L23.7006 20.9711L23.6592 20.8218Z"
        fill="url(#paint14_linear_2017_8853)"
      />
      <path
        d="M28.9688 33.9074C28.3554 33.4887 27.7709 33.0328 27.2321 32.5478L27.0663 32.3986C27.1036 31.0557 26.9793 29.7169 26.6974 28.4153C26.5109 27.5449 26.2498 26.6869 25.9264 25.8662C25.9182 25.8414 25.9057 25.8165 25.8974 25.7916C25.885 25.7585 25.8726 25.7253 25.8601 25.6963C25.8436 25.659 25.8311 25.6258 25.8145 25.5885C25.8021 25.5636 25.7897 25.5346 25.7814 25.5098C25.6239 25.1367 25.4664 24.7968 25.3006 24.4652C25.2011 24.2704 25.114 24.1005 25.027 23.943L24.9524 23.8103L25.0394 23.7855C25.2177 23.7357 25.4083 23.6736 25.6156 23.6072C25.9347 23.5036 26.2622 23.3834 26.6187 23.2383L26.8591 23.342C26.8922 23.3544 26.9254 23.371 26.9585 23.3876C26.9834 23.4 27.0083 23.4124 27.0331 23.4207C27.6673 23.7067 28.2849 24.0342 28.8776 24.3989C28.4756 24.6062 28.1274 24.772 27.7875 24.9212L27.7212 24.9502L27.7502 25.0165C27.9616 25.5429 28.1564 26.1025 28.3264 26.6828C28.546 27.4247 28.716 28.1915 28.8403 28.9625C29.0973 30.5914 29.1429 32.2535 28.9688 33.9074Z"
        fill="url(#paint15_linear_2017_8853)"
      />
      <g clipPath="url(#clip0_2017_8853)">
        <path
          d="M166 27.2483C165.948 27.5221 165.91 27.7959 165.844 28.0649C165.509 29.4574 164.631 30.3874 163.361 30.9774C161.865 31.6713 160.284 31.8129 158.665 31.6949C157.65 31.6194 156.654 31.4353 155.695 31.0718C155.568 31.0246 155.441 30.9585 155.313 30.9066C154.822 30.6942 154.53 30.3307 154.492 29.7926C154.445 29.1412 154.421 28.4851 154.388 27.8289C154.416 27.8195 154.44 27.81 154.468 27.8006C154.525 27.8714 154.581 27.9422 154.633 28.0177C156.399 30.5762 159.458 31.1426 161.856 30.2316C163.092 29.7595 163.692 28.8391 163.621 27.6165C163.583 26.9604 163.281 26.4175 162.856 25.9361C162.186 25.1666 161.332 24.6332 160.487 24.0857C159.302 23.3163 158.108 22.561 156.946 21.7585C156.536 21.4753 156.182 21.0883 155.842 20.7153C154.36 19.0963 154.577 15.9856 157.234 14.8904C158.056 14.5553 158.905 14.3712 159.783 14.3145C161.355 14.2107 162.903 14.3381 164.414 14.8243C164.9 14.9801 164.895 14.9848 164.919 15.4805C164.947 16.0375 164.98 16.5898 165.013 17.227C164.891 17.1137 164.82 17.0476 164.744 16.9815C163.182 15.5088 161.355 15.0462 159.288 15.6079C158.476 15.8298 157.805 16.2735 157.532 17.1232C157.267 17.9492 157.437 18.7328 158.051 19.3323C158.58 19.8515 159.174 20.3141 159.793 20.7248C160.926 21.4659 162.12 22.1126 163.239 22.8678C163.852 23.2785 164.405 23.7977 164.928 24.3264C165.514 24.9212 165.863 25.6576 165.958 26.4978C165.962 26.5355 165.991 26.5686 166.005 26.6016C166 26.8093 166 27.0312 166 27.2483Z"
          fill="#3E2666"
        />
        <path
          d="M140.807 14.6969C141.78 14.6969 142.653 14.6875 143.531 14.6969C144.976 14.7111 146.42 14.7063 147.86 14.7677C148.931 14.8149 149.956 15.0934 150.909 15.6126C153.038 16.7691 153.312 20.0592 151.088 21.4234C150.305 21.9049 149.46 22.1975 148.568 22.3958C148.464 22.4194 148.36 22.4477 148.261 22.4713C148.931 22.5705 149.597 22.646 150.248 22.7734C151.084 22.9339 151.867 23.2407 152.585 23.7175C153.859 24.5624 154.327 25.7661 154.128 27.2483C153.869 29.1506 152.656 30.2174 150.937 30.8169C149.842 31.1993 148.709 31.3125 147.558 31.3125C146.255 31.3125 144.947 31.3125 143.644 31.3078C142.299 31.3031 141.497 30.4912 141.497 29.1459C141.497 24.9023 141.492 20.6634 141.501 16.4198C141.501 15.7826 141.407 15.1925 140.807 14.6969ZM144.074 23.1369C144.065 23.2077 144.055 23.2549 144.055 23.3021C144.055 25.2139 144.046 27.1256 144.069 29.0373C144.074 29.3064 144.206 29.5943 144.343 29.8398C144.489 30.1089 144.74 30.2646 145.079 30.2599C145.386 30.2552 145.693 30.2977 146 30.3213C146.868 30.3779 147.732 30.3685 148.577 30.1277C150.253 29.6557 151.178 28.4379 151.183 26.7102C151.183 25.0534 150.338 23.9346 148.615 23.5145C147.704 23.2927 146.75 23.2313 145.816 23.1463C145.24 23.0944 144.659 23.1369 144.074 23.1369ZM144.069 15.7495C144.069 17.902 144.069 20.0309 144.069 22.1598C144.655 22.1598 145.221 22.1881 145.783 22.1551C146.802 22.089 147.784 21.8671 148.69 21.362C150.725 20.2244 150.531 17.4677 148.742 16.4482C147.515 15.7495 146.184 15.6362 144.815 15.6835C144.57 15.6882 144.324 15.7259 144.069 15.7495Z"
          fill="#3E2666"
        />
        <path
          d="M89.9409 22.8442C90.2619 22.9953 90.6206 23.0944 90.8944 23.3068C91.3098 23.6231 91.7252 23.9724 92.0414 24.3831C92.9336 25.5254 93.7644 26.7196 94.6471 27.8714C95.0955 28.4567 95.577 29.0185 96.0868 29.5424C96.9506 30.4298 97.9513 31.0907 99.2117 31.2842C97.9796 31.4306 96.7476 31.4778 95.5156 31.3503C95.0341 31.3031 94.5857 31.1332 94.26 30.7839C93.6416 30.123 93.0138 29.4669 92.4852 28.7352C91.6308 27.5504 90.8236 26.3279 90.0495 25.0864C89.1856 23.7081 87.897 23.2502 86.3676 23.2219C86.3298 23.2219 86.2873 23.2219 86.2496 23.2313C86.2307 23.236 86.2165 23.2455 86.1599 23.2738C86.1599 23.3823 86.1599 23.5098 86.1599 23.6372C86.1599 25.5868 86.1693 27.541 86.1552 29.4905C86.1504 30.1561 86.2968 30.7508 86.8018 31.2748C85.5179 31.2748 84.2765 31.2748 82.9736 31.2748C83.6298 30.7225 83.6109 29.9908 83.6109 29.2592C83.6062 25.0486 83.6062 20.8334 83.6109 16.6228C83.6109 15.9147 83.5967 15.2209 82.917 14.7016C83.0633 14.6969 83.1294 14.6875 83.1955 14.6875C85.1167 14.6875 87.0379 14.6686 88.959 14.7016C90.1958 14.7252 91.3759 14.999 92.471 15.6174C94.8123 16.9391 95.1097 20.4416 92.4946 21.9521C91.7063 22.4288 90.8472 22.7073 89.9409 22.8442ZM86.1835 22.2306C86.7405 22.2306 87.2644 22.2495 87.7884 22.2259C88.6664 22.1834 89.4972 21.9521 90.2194 21.4281C91.2437 20.6823 91.6685 19.6533 91.5222 18.4118C91.3853 17.2553 90.6867 16.5142 89.6435 16.0658C89.0251 15.7967 88.369 15.6834 87.6987 15.674C87.1984 15.6693 86.698 15.7165 86.1882 15.7401C86.1835 17.9115 86.1835 20.0167 86.1835 22.2306Z"
          fill="#3E2666"
        />
        <path
          d="M68.2084 31.3031C66.8962 31.3031 65.6547 31.3031 64.3424 31.3031C65.0411 30.7131 64.9797 29.9436 64.9797 29.1884C64.9797 24.9353 64.9797 20.687 64.9797 16.434C64.9797 15.8015 64.8995 15.202 64.333 14.7252C64.4416 14.7158 64.4982 14.7016 64.5501 14.7016C66.5233 14.7016 68.4916 14.678 70.4647 14.7158C71.8478 14.7441 73.1648 15.084 74.3118 15.91C76.4454 17.4441 76.1245 20.569 74.4346 21.9851C73.4716 22.7876 72.3482 23.203 71.135 23.4154C70.0399 23.6089 68.9401 23.6561 67.8355 23.5428C67.7505 23.5334 67.6609 23.5428 67.5334 23.5428C67.5334 23.6703 67.5334 23.7836 67.5334 23.9016C67.5334 25.752 67.5428 27.6071 67.5287 29.4574C67.5192 30.1419 67.6561 30.7555 68.2084 31.3031ZM67.5287 19.1293C67.5287 20.1536 67.5334 21.1779 67.524 22.2023C67.524 22.4147 67.5617 22.5374 67.8072 22.5421C68.402 22.5516 69.0014 22.6318 69.5915 22.5893C71.2578 22.4713 72.773 21.5178 72.976 19.4125C73.1365 17.6943 72.4237 16.3679 70.6252 15.877C69.7001 15.6221 68.7607 15.6174 67.8166 15.7448C67.5853 15.7779 67.524 15.8581 67.524 16.08C67.5381 17.0996 67.5287 18.1144 67.5287 19.1293Z"
          fill="#3E2666"
        />
        <path
          d="M131.489 14.7016C132.419 14.7016 133.264 14.6922 134.109 14.7111C134.199 14.7111 134.326 14.8527 134.374 14.9612C135.393 17.3592 136.403 19.7571 137.413 22.1598C138.48 24.6993 139.552 27.2342 140.619 29.7737C140.855 30.3354 141.124 30.8783 141.666 31.2937C140.491 31.2937 139.363 31.2937 138.183 31.2937C138.622 30.9491 138.395 30.5762 138.244 30.2269C137.64 28.7872 137.031 27.3522 136.408 25.9219C136.366 25.8275 136.229 25.7189 136.134 25.7142C134.761 25.667 133.392 25.6198 132.018 25.6151C131.145 25.6104 130.267 25.6764 129.394 25.7001C129.167 25.7048 129.04 25.7756 128.955 26.0022C128.756 26.5308 128.506 27.0406 128.327 27.5788C128.171 28.0461 127.888 28.3859 127.491 28.6597C127.066 28.9524 126.646 29.2498 126.07 29.3347C126.113 29.2167 126.137 29.127 126.174 29.0421C127.548 25.6859 128.922 22.325 130.29 18.9688C130.748 17.8454 131.206 16.7219 131.669 15.6032C131.796 15.2869 131.848 14.999 131.489 14.7016ZM132.665 16.9674C131.603 19.5117 130.569 21.9946 129.521 24.5105C131.65 24.6757 133.727 24.6474 135.851 24.5058C134.789 21.9946 133.746 19.5164 132.665 16.9674Z"
          fill="#3E2666"
        />
        <path
          d="M51.6211 27.8054C52.1545 28.6315 52.82 29.3206 53.6744 29.8068C55.3785 30.7745 57.1675 30.902 58.9895 30.2269C60.2215 29.7691 60.8069 28.8533 60.7502 27.6402C60.7219 27.1068 60.5142 26.6395 60.2027 26.2099C59.6881 25.5066 59.0084 24.9874 58.2862 24.52C57.1439 23.7789 55.9638 23.0898 54.8451 22.3156C54.1701 21.8483 53.5328 21.3055 52.9758 20.706C51.6116 19.2238 51.909 16.9816 52.9522 15.8676C53.6272 15.1501 54.4769 14.7536 55.4115 14.5459C57.4885 14.0833 59.5418 14.2108 61.5716 14.8292C62.0342 14.9708 62.0247 14.9802 62.0483 15.4758C62.0766 16.0328 62.1097 16.5851 62.1427 17.2224C62.02 17.1091 61.9492 17.043 61.8737 16.9769C60.3112 15.4994 58.4845 15.0463 56.4169 15.5986C55.7514 15.7779 55.1755 16.1273 54.8167 16.7551C54.3589 17.5622 54.4627 18.596 55.1802 19.3182C55.7089 19.8516 56.3178 20.3142 56.9456 20.7249C58.1965 21.5509 59.5041 22.3015 60.7549 23.1275C61.2223 23.4344 61.6282 23.8498 62.02 24.2557C63.8845 26.1863 63.2662 29.4339 60.9862 30.7226C59.7684 31.4118 58.4467 31.6855 57.0683 31.7233C55.6239 31.7658 54.2125 31.5723 52.8484 31.0766C52.6879 31.02 52.5368 30.9444 52.3858 30.8736C51.9562 30.6754 51.6824 30.3497 51.6447 29.8682C51.5927 29.1885 51.5597 28.5087 51.5172 27.8243C51.5503 27.8196 51.588 27.8101 51.6211 27.8054Z"
          fill="#3E2666"
        />
        <path
          d="M107.704 31.2984C106.382 31.2984 105.122 31.2984 103.805 31.2984C104.432 30.765 104.451 30.0805 104.451 29.3725C104.451 26.6441 104.475 23.9157 104.484 21.1874C104.489 19.5872 104.494 17.9823 104.498 16.3821C104.498 16.3113 104.494 16.2452 104.484 16.113C103.016 16.1744 101.548 15.9667 100.042 16.3537C100.274 15.8109 100.486 15.2917 100.717 14.7819C100.746 14.7252 100.906 14.7063 101.005 14.7063C102.28 14.7016 103.554 14.7158 104.829 14.7016C107.099 14.6733 109.37 14.6308 111.64 14.593C111.777 14.593 111.914 14.593 112.112 14.593C111.494 15.438 110.876 16.1696 109.748 16.1366C109.002 16.113 108.256 16.0988 107.505 16.08C107.368 16.0752 107.232 16.08 107.029 16.08C107.029 16.4576 107.029 16.8211 107.029 17.1892C107.019 20.5454 107.01 23.9016 107.005 27.2578C107.005 27.9328 107.024 28.6125 106.996 29.2875C106.972 30.0333 107.062 30.7319 107.704 31.2984Z"
          fill="#3E2666"
        />
        <path
          d="M127.741 28.9901C127.463 29.7123 127.18 30.444 126.887 31.1709C126.863 31.2323 126.764 31.2795 126.694 31.3031C126.632 31.3267 126.557 31.3078 126.486 31.3078C123.918 31.3078 121.35 31.3078 118.778 31.3125C118.669 31.3125 118.56 31.3125 118.504 31.3125C118.664 30.9491 118.834 30.5809 118.981 30.2033C119.032 30.0711 119.018 29.9106 119.018 29.7595C119.018 25.2563 119.018 20.7578 119.023 16.2546C119.023 15.6693 118.886 15.1501 118.386 14.7252C119.693 14.7252 120.954 14.7252 122.256 14.7252C121.652 15.1878 121.572 15.8156 121.577 16.4954C121.586 20.8664 121.581 25.2374 121.581 29.6085C121.581 30.0192 121.581 30.0286 121.978 30.0333C122.823 30.0475 123.673 30.1089 124.513 30.0428C125.23 29.9861 125.948 29.8304 126.637 29.6274C127.033 29.5141 127.373 29.212 127.741 28.9901Z"
          fill="#3E2666"
        />
        <path
          d="M80.8542 31.2937C79.5467 31.2937 78.3052 31.2937 77.0543 31.2937C77.059 31.2701 77.0543 31.237 77.0732 31.2229C77.5216 30.8452 77.6633 30.3402 77.6633 29.7784C77.668 28.2066 77.6633 26.63 77.6633 25.0581C77.6633 22.1975 77.6585 19.3417 77.668 16.4812C77.668 15.8109 77.5641 15.1973 76.9835 14.7111C78.2911 14.7111 79.5467 14.7111 80.8778 14.7111C80.2311 15.2114 80.2122 15.8723 80.217 16.552C80.2217 20.8145 80.2217 25.077 80.217 29.3442C80.217 30.0475 80.2264 30.7414 80.8542 31.2937Z"
          fill="#3E2666"
        />
        <path
          d="M98.8198 30.4959C97.8286 30.1041 96.9931 29.5849 96.3464 28.7966C96.2708 28.7069 96.2708 28.537 96.2708 28.4048C96.2661 24.4539 96.2661 20.4982 96.2708 16.5473C96.2708 15.8628 96.2142 15.2161 95.5817 14.7111C96.9034 14.7111 98.1495 14.7111 99.4051 14.7111C99.4099 14.7252 99.424 14.7488 99.4193 14.7583C98.9426 15.2728 98.8198 15.9006 98.8198 16.5803C98.8245 19.2096 98.8245 21.8388 98.8245 24.4633C98.8245 26.3326 98.8245 28.2066 98.8245 30.0758C98.8198 30.1985 98.8198 30.3213 98.8198 30.4959Z"
          fill="#3E2666"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_2017_8853"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.23 8.59926) scale(7.28143)"
        >
          <stop stopColor="#80377C" />
          <stop offset="1" stopColor="#28246B" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_2017_8853"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.9677 14.7891) scale(6.66756)"
        >
          <stop stopColor="#80377C" />
          <stop offset="1" stopColor="#28246B" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_2017_8853"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.5576 37.4067) scale(7.27508)"
        >
          <stop stopColor="#80377C" />
          <stop offset="1" stopColor="#28246B" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_2017_8853"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.7694 31.268) scale(6.69061)"
        >
          <stop stopColor="#80377C" />
          <stop offset="1" stopColor="#28246B" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_2017_8853"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.5989 11.9247) scale(6.67722)"
        >
          <stop stopColor="#80377C" />
          <stop offset="1" stopColor="#28246B" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_2017_8853"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.49788 22.6605) scale(7.27633)"
        >
          <stop stopColor="#80377C" />
          <stop offset="1" stopColor="#28246B" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_2017_8853"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.0776 34.1237) scale(6.68054)"
        >
          <stop stopColor="#80377C" />
          <stop offset="1" stopColor="#28246B" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_2017_8853"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.3041 23.3357) scale(7.28176)"
        >
          <stop stopColor="#80377C" />
          <stop offset="1" stopColor="#28246B" />
        </radialGradient>
        <linearGradient
          id="paint8_linear_2017_8853"
          x1="26.575"
          y1="13.3405"
          x2="20.1799"
          y2="20.0326"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#905E25" />
          <stop offset="1" stopColor="#EDC55F" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_2017_8853"
          x1="18.924"
          y1="32.3804"
          x2="25.6677"
          y2="26.287"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#905E25" />
          <stop offset="1" stopColor="#EDC55F" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_2017_8853"
          x1="13.0572"
          y1="19.4058"
          x2="19.8891"
          y2="25.5788"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#905E25" />
          <stop offset="1" stopColor="#EDC55F" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_2017_8853"
          x1="12.0093"
          y1="27.0699"
          x2="22.6199"
          y2="27.0699"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#905E25" />
          <stop offset="1" stopColor="#EDC55F" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_2017_8853"
          x1="33.2232"
          y1="27.7473"
          x2="25.9297"
          y2="20.115"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#905E25" />
          <stop offset="1" stopColor="#EDC55F" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_2017_8853"
          x1="18.8475"
          y1="13.4284"
          x2="18.8475"
          y2="22.8136"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#905E25" />
          <stop offset="1" stopColor="#EDC55F" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_2017_8853"
          x1="32.3748"
          y1="18.9033"
          x2="23.2494"
          y2="18.9033"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#905E25" />
          <stop offset="1" stopColor="#EDC55F" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_2017_8853"
          x1="27.0111"
          y1="33.9091"
          x2="27.0111"
          y2="23.2589"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#905E25" />
          <stop offset="1" stopColor="#EDC55F" />
        </linearGradient>
        <clipPath id="clip0_2017_8853">
          <rect
            width="114.483"
            height="17.4653"
            fill="white"
            transform="translate(51.5172 14.2673)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LogoSVG;
