import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { useRouter } from 'next/router';

import LogoSVG from './LogoSVG';
import { breakPoints } from 'constant/breakPoints';
import { APP_ROUTES } from 'constant/appConstant';

const ContactButton = React.memo(() => (
  <Link href={APP_ROUTES.CONTACT_US} passHref>
    <BorderButton className="text-center button button-outline header-contact-us">
      Contact Us
    </BorderButton>
  </Link>
));
const Logo = React.memo(() => (
  <div className="content-logo">
    <Link href={APP_ROUTES.HOME}>
      <a>
        <LogoSVG />
      </a>
    </Link>
  </div>
));

function Header(): JSX.Element {
  const router = useRouter();

  const selfRef = useRef<HTMLHeadElement>(null);

  const scrollBehaviorRef = useRef({
    didScroll: false,
    isLandingRoof: true,
    lastScrollTop: 0,
    delta: 150,

    documentHeight: 0,
    viewPortHeight: 0,
  });

  const [showBlurMenu, setShowBlurMenu] = useState(false);
  const [isCollapsedNav, setCollapsedNav] = useState(false);

  const navRouters = useMemo(() => {
    return NAV_ROUTERS.map((el) =>
      router.route.startsWith(el.href)
        ? {
            ...el,
            active: true,
          }
        : el,
    );
  }, [router.route]);

  const onClickMenu = () => {
    setShowBlurMenu((prev) => {
      const newState = !prev;

      if (newState) {
        selfRef.current?.classList.add('header-fixed');
      } else {
        selfRef.current?.classList.remove('header-fixed');
      }
      return newState;
    });
  };

  const onClickMask = () => {
    setShowBlurMenu(false);
  };

  const updateHeaderStateFirstMount = useCallback(() => {
    scrollBehaviorRef.current.documentHeight =
      document.documentElement.scrollHeight;
    scrollBehaviorRef.current.viewPortHeight = window.innerHeight;
  }, []);

  // observer #HEADER_PLACE_ID elem when user scrolled over
  useEffect(() => {
    const headerPlace = document.getElementById(HEADER_PLACE_ID);
    if (!headerPlace) return;

    const options = {
      rootMargin: '20px 0px 0px 0px',
    };
    const sectionOneObserver = new IntersectionObserver(function (entries) {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          selfRef.current?.classList.add('header-collapsed');
          scrollBehaviorRef.current.isLandingRoof = false;
          setCollapsedNav(true);
        } else {
          selfRef.current?.classList.remove('header-collapsed');
          selfRef.current?.classList.remove('header-up');

          scrollBehaviorRef.current.isLandingRoof = true;
          scrollBehaviorRef.current.didScroll = true; // trigger for check scrolled
          setCollapsedNav(false);
        }
      });
    }, options);

    sectionOneObserver.observe(headerPlace);

    return () => {
      sectionOneObserver.unobserve(headerPlace);
    };
  }, []);

  useEffect(() => {
    updateHeaderStateFirstMount();
  }, [updateHeaderStateFirstMount]);

  // hide header when scroll down, show header scroll up
  useEffect(() => {
    let interval: NodeJS.Timer | undefined = undefined;

    if (window.innerWidth < breakPoints.desktopS) return;
    window.onscroll = function () {
      updateDidScroll();
    };

    interval = setInterval(function () {
      if (scrollBehaviorRef.current.didScroll) {
        hasScrolled();
        scrollBehaviorRef.current.didScroll = false;
      }
    }, 250);

    function hasScrolled() {
      const bodyScrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;

      // Make sure they scroll more than delta
      if (
        !bodyScrollTop ||
        Math.abs(scrollBehaviorRef.current.lastScrollTop - bodyScrollTop) <=
          scrollBehaviorRef.current.delta
      )
        return;

      // If they scrolled down and are past the navbar and header not reaching the top page add class .header-up.
      if (
        bodyScrollTop > scrollBehaviorRef.current.lastScrollTop &&
        !scrollBehaviorRef.current.isLandingRoof
      ) {
        // Scroll Down
        selfRef.current?.classList.remove('header-down');
        selfRef.current?.classList.add('header-up');
      } else {
        // Scroll Up
        if (
          bodyScrollTop + scrollBehaviorRef.current.viewPortHeight <
          scrollBehaviorRef.current.documentHeight
        ) {
          selfRef.current?.classList.remove('header-up');
          selfRef.current?.classList.add('header-down');
        }
      }

      scrollBehaviorRef.current.lastScrollTop = bodyScrollTop;
    }

    function updateDidScroll() {
      scrollBehaviorRef.current.didScroll = true;
    }

    return () => {
      interval && clearInterval(interval);
      window.removeEventListener('scroll', updateDidScroll);
    };
  }, []);

  return (
    <>
      <Wrap ref={selfRef}>
        <Mask
          className={showBlurMenu ? 'activate' : ''}
          onClick={onClickMask}
        />

        <div className="content">
          <Logo />
          <nav>
            <ul className={showBlurMenu ? 'activate' : ''}>
              {navRouters.map((item, idx) => (
                <li
                  key={idx}
                  className={
                    item.active ? 'text-gray2 route-active' : 'text-gray2'
                  }
                  {...(!item.disable && {
                    onClick: onClickMask,
                  })}
                >
                  <Link href={item.href} prefetch={false}>
                    <a className={item.disable ? 'disabled-link' : ''}>
                      {item.text}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
            <ContactButton />

            <HamburgerButton
              className={isCollapsedNav ? 'show' : ''}
              type="button"
              onClick={onClickMenu}
            >
              <span
                className={`menu__hamburger m-hamburger ${
                  showBlurMenu ? 'activate' : ''
                }`}
              >
                <span className="m-hamburger__label">
                  <span className="screen-reader">
                    {showBlurMenu ? 'Close menu' : 'Open menu'}
                  </span>
                </span>
              </span>
            </HamburgerButton>
          </nav>
        </div>
      </Wrap>

      <div id={HEADER_PLACE_ID} />
    </>
  );
}

export default Header;

const HEADER_INDEX = 900;
const BACKDROP_INDEX = 910;
const HAMBURGER_INDEX = 950;
const NAV_LINK_INDEX = 930;
const HEADER_PLACE_ID = 'header-place';

const NAV_ROUTERS = [
  {
    text: 'Services',
    href: APP_ROUTES.SERVICES,
    active: false,
  },
  {
    text: 'Blog',
    href: '/blog',
    active: false,
    disable: false,
  },
  {
    text: 'Career',
    href: APP_ROUTES.CAREER,
    active: false,
  },
];

const Wrap = styled.header`
  height: 65px;
  display: flex;
  align-items: flex-end;
  background-color: transparent;
  position: fixed;
  z-index: ${HEADER_INDEX};
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.4s ease-in-out;
  color: var(---gray2);

  .content {
    max-width: 1172px;
    overflow: hidden;
    padding: 0 16px 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  nav {
    display: flex;
    align-items: center;
  }

  ul {
    margin: 0;
    list-style: none;
    display: flex;
    padding-inline-start: 0;

    li {
      padding-left: 64px;
      font-weight: 500;

      a {
        display: block;
        width: 100%;
      }

      &:hover {
        color: var(--white);
      }

      /* link active when user land on per route */
      &.route-active {
        font-weight: 600px;
        color: var(--white);

        a {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            height: 3px;
            background-color: var(--purple-branding);
            border-radius: 5px 5px 0 0;
            bottom: -15px;
            right: 0;
            width: 100%;
          }
        }
      }
    }

    /* link active when user land on per route */
    &.activate {
      position: absolute;
      z-index: ${NAV_LINK_INDEX};
      flex-direction: column;
      align-content: flex-end;
      align-items: flex-end;
      text-align: right;

      li {
        width: fit-content;
      }
    }
  }

  &.header-collapsed {
    background-color: var(--purple-darken);
  }

  @media screen and (max-width: ${breakPoints.tabletS - 1}px) {
    height: 90px;
    align-items: center;

    .content {
      .content-logo {
        width: 110px;

        svg {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  @media only screen and (max-width: ${breakPoints.desktopS - 1}px) {
    ul {
      position: absolute;
      width: 0;
      display: none;
      opacity: 0;
      /* transition: opacity 0.8s 2s ease; */

      @keyframes showSideBar {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      &.activate {
        display: block;
        top: 100px;
        left: 0;
        width: 100%;
        opacity: 1;
        padding: 0 60px;
        font-size: 24px;
        animation-name: showSideBar;
        animation-duration: 1s;

        li {
          margin-left: auto;
          margin-bottom: 32px;

          &.route-active {
            a {
              &:after {
                bottom: -5px;
              }
            }
          }
        }
      }
    }

    &.header-collapsed {
      background-color: var(--color-background);
      backdrop-filter: blur(28px);
    }
  }

  @media screen and (min-width: ${breakPoints.tabletL}px) {
    height: 82px;

    &.header-collapsed {
      padding-bottom: 16px;
    }
  }

  @media only screen and (min-width: ${breakPoints.desktopS}px) {
    ul {
      position: relative;

      &.activate {
        top: 107px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 1;
        max-width: 1008px;
        width: 100%;
        padding: 0 20px;
        font-size: 26px;

        li {
          margin-bottom: 24px;
        }
      }
    }

    &.header-up {
      transform: translateY(-100%);
      /* 
      &.header-fixed {
        transform: translateY(0);
      } */
    }
    &.header-down {
      transform: translateY(0);
    }
  }

  @media screen and (min-width: ${breakPoints.desktopL}px) {
    height: 102px;
  }
`;

const HamburgerButton = styled.button`
  height: 32px;
  width: 32px;
  position: relative;
  border-style: none;
  background-color: transparent;

  z-index: ${HAMBURGER_INDEX};

  /* variable */
  --uiHamburgerThickness: 3px;

  .m-hamburger {
    display: inline-flex;
    width: 24px;
    height: 21px;

    position: relative;

    /* hamburger style */
    &::before,
    &::after,
    &__label {
      width: 100%;
      height: var(--uiHamburgerThickness);
      border-radius: 5px;
      background-color: var(--white);

      position: absolute;
      left: 0;
    }

    &::before,
    &::after {
      content: '';
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    &__label {
      top: calc(50% - calc(var(--uiHamburgerThickness) / 2));
    }

    /* hamburger animation */
    &::before,
    &::after,
    &__label {
      transition-timing-function: ease;
      transition-duration: 0.15s;
    }

    &::before,
    &::after {
      transition-property: transform;
    }

    &__label {
      transition-property: transform, opacity;
    }

    /* hamburger rotate anim to "X" */
    &.activate {
      &::before {
        top: 50%;
        transform: translate3d(0, -50%, 0) rotate(45deg);
      }
      &::after {
        transform: translate3d(0, -50%, 0) rotate(135deg);
        top: 50%;
      }

      .m-hamburger__label {
        transform: rotate(-45deg) translate3d(-0.285em, -0.3em, 0);
        opacity: 0;
      }
    }
  }

  margin-left: 17px;

  &.show {
    transform: translate(0, 0);
    animation-delay: 1s;
    width: 32px;
    opacity: 1;
  }

  @media only screen and (min-width: ${breakPoints.desktopS}px) {
    display: none;
    visibility: hidden;
  }
`;

const BorderButton = styled.a`
  display: inline-block;
  background-color: transparent;
  border-radius: 100px;
  z-index: 1;
  width: max-content;
  padding: 7.5px 31px;
  line-height: normal;
  font-size: 14px;
  transition: 0.1s ease;

  &:hover {
    background-color: var(--white);
    color: var(--purple-branding);
  }

  @media only screen and (min-width: ${breakPoints.tabletL}px) {
    width: 134px;
    padding: 14px 0;
    font-size: 16px;
    margin-left: 64px;
    position: relative;
    overflow: hidden;
  }
`;

const Mask = styled.div`
  width: 1px;
  height: 1px;

  background-color: var(--color-background);
  border-radius: 50%;

  position: fixed;
  top: -1px;
  right: -1px;
  z-index: ${BACKDROP_INDEX};

  will-change: width, height;
  transition: transform 0.25s cubic-bezier(0.04, -0.1, 0.29, 0.98),
    width 0.25s cubic-bezier(0.04, -0.1, 0.29, 0.98),
    height 0.25s cubic-bezier(0.04, -0.1, 0.29, 0.98);

  &.activate {
    width: 100vmax;
    height: 100vmax;
    transform: translate3d(80vh, -90vh, 0) scale(5);
    transition-duration: 0.8s;
  }
`;
