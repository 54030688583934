export const ELEM_APP_MODAL_ID = 'app-modal';
export const ELEM_JOB_OPENING_ID = 'opening-job';
export const API_URL = 'http://localhost:1337';

/**
 * {@link https://www.stackoverflow.com/a/29767609/7907987}
 */
export const PHONE_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/m;

export const SEO_FOR_PAGE = {
  BLOG: {
    siteName: 'Blog: Spirit Labs',
    defaultSeo: {
      metaTitle: 'Update Technology Insights and Trends',
      metaDescription:
        'Enjoy your reading and researching in our blogs! Keep update with the newest insights',
    },
  },
  CAREER: {
    siteName: 'Career: Spirit Labs',
    defaultSeo: {
      metaTitle: 'Your next career destination',
      metaDescription:
        "It's time to play a bigger game! We're looking for dedicated, energetic and self-confident talents to join Spirit Labs to build the big things together.",
    },
  },
};

export const SOCIAL = {
  FACEBOOK: 'https://www.facebook.com/spiritlabs.co/',
  TWITTER: 'https://twitter.com/spiritlabs_vn',
  LINKEDIN: 'https://www.linkedin.com/company/spirit-labs',
};

export const CONTACT_PHONE = {
  LABEL: '(+84) 28 38 212 227',
  HREF: '+8438212227',
};

export const CONTACT_EMAIL = {
  LABEL: 'hello@spiritlabs.co',
  HREF: 'hello@spiritlabs.co',
};

export const HUMAN_RESOURCE_EMAIL = {
  LABEL: 'hr@spiritlabs.co',
  HREF: 'hr@spiritlabs.co',
};

export const CORE_VALUE = [
  {
    id: 'innovation',
    icon: '/images/icon/innovation.svg',
    title: 'Innovation',
    des: 'Always keep a learning spirit along with ourselves, teammates and customers to self-improve constantly and be curious about how things work.',
  },
  {
    id: 'customer',
    icon: '/images/icon/customer-first.svg',
    title: 'Customer First',
    des: 'Our goal is to deliver every single client project successfully. With this in mind, we organize ourselves to achieve this ultimate goal.',
  },
  {
    id: 'iterity',
    icon: '/images/icon/iterity.svg',
    title: 'Integrity',
    des: 'We are honest, transparent and committed to doing what is best for our clients and our employees. Building Mutual Trust is at the heart of every engagement we are in.',
  },
  {
    id: 'agility',
    icon: '/images/icon/agility.svg',
    title: 'Agility',
    des: 'We acknowledge the importance of timely changes in the project delivery. We work with proven agile practices to deliver the product our clients desire for, in a cost-effective way.',
  },
  {
    id: 'family',
    icon: '/images/icon/family-spirit.svg',
    title: 'Family Spirit',
    des: "Life is filled with challenges and difficulties. Let's make Spirit Labs as home by living and working with Caring and Respect.",
  },
];

export const APP_ROUTES = {
  HOME: '/',
  CAREER: '/career',
  CONTACT_US: '/contact-us',
  SERVICES: '/services',
  BLOG: '/blog',
  LEGAL_PRIVACY: '/legal-privacy',
  SERVICES_DESIGN: '/services#ux-ui-design',
  SERVICES_WEB: '/services#web-application-development',
  SERVICES_MOBILE: '/services#mobile-app-development',
  SERVICES_BLOCKCHAIN: '/services#blockchain-development',
  SERVICES_DEDICATE: '/services#dedicated-development-team',
};

export const BANNER_CONTENT = [
  {
    title: 'UI/UX <br/> Creative',
    subtitle: 'Upgrade your business by approaching user-centric',
    link: APP_ROUTES.SERVICES_DESIGN,
  },
  {
    title: 'Web & Mobile <br />App Development',
    subtitle: 'Build your game-changing products',
    link: APP_ROUTES.SERVICES_WEB,
  },
  {
    title: 'Blockchain <br/> Development',
    subtitle: 'Future is decentralized. Start innovation',
    link: APP_ROUTES.SERVICES_BLOCKCHAIN,
  },
  {
    title: 'Dedicated <br /> Team Services',
    subtitle: 'Boost your resources with our tech talents',
    link: APP_ROUTES.SERVICES_DEDICATE,
  },
];

export const SERVICES = [
  {
    title: 'Blockchain <br />Development',
    icon: '/images/icon/specialized/blockchain.svg',
    img: '/images/services/mobile/blockchain.webp',
    description:
      'We provide the blockchain development resources specializing with blockchain network integration and deployment, smart contracts, dApps, NFT marketplaces.',
    link: APP_ROUTES.SERVICES_BLOCKCHAIN,
    id: 'blockchain-development',
  },
  {
    title: 'Web App <br />Development',
    icon: '/images/icon/specialized/web.svg',
    img: '/images/services/mobile/web.webp',
    description:
      'Our expertise honed in 15+ years of experience delivering full-stack web development ranging from websites to business platforms.',
    link: APP_ROUTES.SERVICES_WEB,
    id: 'web-application-development',
  },
  {
    title: 'Mobile App <br />Development',
    icon: '/images/icon/specialized/mobile.svg',
    img: '/images/services/mobile/mobile.webp',
    description:
      'We specialize in building the mobile apps with hybrid technologies like ReactNative, Flutter.',
    link: APP_ROUTES.SERVICES_MOBILE,
    id: 'mobile-app-development',
  },
  {
    title: 'Creative <br />Design',
    icon: '/images/icon/specialized/design.svg',
    img: '/images/services/mobile/design.webp',
    description:
      'We bring the hands-on and user-centric approach to design thinking. We leverage the real-time collaboration tools to work with our clients on the design process from defining user stories to sketching, wireframing, prototyping and validating the design solutions.',
    link: APP_ROUTES.SERVICES_DESIGN,
    id: 'ux-ui-design',
  },
  {
    icon: '/images/icon/specialized/services.svg',
    title: 'Dedicated <br />Team Services',
    img: '/images/services/mobile/dedicate.webp',
    description:
      'With 15+ years of experience in delivering IT development services, we know how to build an effective development team to realize your product vision and roadmap.',
    link: APP_ROUTES.SERVICES_DEDICATE,
    id: 'dedicated-development-team',
  },
];

export const OUR_KEY_SERVICE = [
  {
    title: 'Blockchain Development',
    id: 'blockchain-development',
    keys: [
      {
        icon: 'images/icon/services/decentralized.svg',
        title: 'Decentralized Application (dApps) Development',
        des: 'From Ideation to Creation, we build enterprise-grade decentralized applications for a wide range of sectors and integrate blockchain-based components into existing solutions.',
      },
      {
        icon: 'images/icon/services/smart-contract.svg',
        title: 'Smart Contract Development',
        des: 'Farewell to low-value, time-consuming operational processes. We develop and deploy digital contracts to secure your transactions in public and private blockchain networks.',
      },
    ],
  },
  {
    title: 'Web Application Development',
    id: 'web-application-development',
    keys: [
      {
        icon: 'images/icon/services/people-involved.svg',
        title: 'People involved',
        des: 'Frontend & Backend developers, QA Engineers, Project Manager, Business Analyst (if required)',
      },
      {
        icon: 'images/icon/services/expertise.svg',
        title: 'Expertise',
        des: `Frameworks: Nextjs, Angular, Laravel, CakePhp, Nestjs
        <br />
        Libraries: React
        <br />
        Databases: Mysql, MongoDB, Postgresql, Elasticsearch
        <br />
        Languages: Javascript, Nodejs, PHP`,
      },
      {
        icon: 'images/icon/services/timeline.svg',
        title: 'Timeline',
        des: '3+ months',
      },
    ],
  },
  {
    title: 'Mobile Application Development',
    id: 'mobile-app-development',
    keys: [
      {
        icon: 'images/icon/services/people-involved.svg',
        title: 'People involved',
        des: 'Mobile & Backend developers, QA Engineers, Project Manager, Business Analyst (if required)',
      },
      {
        icon: 'images/icon/services/expertise.svg',
        title: 'Expertise',
        des: `Frameworks: Nextjs, Angular, Laravel, CakePhp, Nestjs
        <br />
        Libraries: React
        <br />
        Databases: Mysql, MongoDB, Postgresql, Elasticsearch
        <br />
        Languages: Javascript, Nodejs, PHP`,
      },
      {
        icon: 'images/icon/services/timeline.svg',
        title: 'Timeline',
        des: '3+ months',
      },
    ],
  },
  {
    title: 'Creative Design',
    id: 'ux-ui-design',
    keys: [
      {
        icon: 'images/icon/services/ui-design.svg',
        title: 'UI Design',
        des: 'Apply creative thinking and design trends merged with digital media to create future-ready user interfaces.',
      },
      {
        icon: 'images/icon/services/ux-design.svg',
        title: 'UX Design',
        des: 'Explore all possibilities for providing the end-user with simple, efficient, relevant, and overall enjoyable experiences.',
      },
    ],
  },
  {
    title: 'Dedicated Team Services',
    id: 'dedicated-development-team',
    keys: [
      {
        icon: 'images/icon/services/cost-effective-pricing.svg',
        title: 'Cost-Effective Pricing',
        des: 'We will manage the recruitment, infrastructure, insurance, bonuses, and more while you focus on the success of your product.',
      },
      {
        icon: 'images/icon/services/scalable-solutions.svg',
        title: 'Scalable Solutions',
        des: 'Your project is long-term and it allows you to easily scale up and reconfigure the team at any stage of the working process as required.',
      },
      {
        icon: 'images/icon/services/gaining-specific-expertise.svg',
        title: 'Team with Specific Expertise',
        des: 'We are ready to offer a pool of specific expertise – frontend, backend development, QA/QC, or UX/UI design to leverage your in-house resources with the agile practices around major domains.',
      },
      {
        icon: 'images/icon/services/inside-out-control-engagement.svg',
        title: 'Inside-out Control & Engagement',
        des: 'Clients get the full control over the motivation and management of the dedicated team, resources and infrastructure, all of which are tailored to the project requirements.',
      },
    ],
  },
];

export const WHY_US = [
  {
    title: 'Right at <span>the first time</span>',
    des: 'We kick off the partnership with our clients with the free consultation session organized as a workshop to validate and strengthen the product feasibility to the market. This is the side value to our development services but it is critical for the product survival.',
  },
  {
    title: '<span>Get it done</span> in the agile way',
    des: 'We organize ourselves around the agile practices with a strong commitment to getting things done and providing our partners amazing experiences of developing their products with us.',
  },
  {
    title: 'Clean. Neat. <span>Scalable</span>',
    des: 'We are coding lovers. We want to build a great legacy that the next team can continue our work. Code refactoring is an extra mile we provide for our client even if they do not ask for.',
  },
];

export const JOB_OPTIONS = [
  {
    value: 'Web Application Development',
    label: 'Web Application Development',
  },
  { value: 'Mobile App  Development', label: 'Mobile App  Development' },
  { value: 'UX / UI Creative', label: 'UX / UI Creative' },
  { value: 'Dedicated Team Services', label: 'Dedicated Team Services' },
  { value: 'Blockchain Development', label: 'Blockchain Development' },
];

export const PARTNERS = [
  {
    id: 1,
    src: '/images/partners/fluent-up.webp',
  },
  {
    id: 2,
    src: '/images/partners/hex.webp',
  },
  {
    id: 3,
    src: '/images/partners/energy.webp',
  },
  {
    id: 4,
    src: '/images/partners/karus.webp',
  },
  {
    id: 5,
    src: '/images/partners/quikspaces.webp',
  },
  {
    id: 6,
    src: '/images/partners/pakt.webp',
  },
];

export const JOBS = [
  {
    id: '5 - Blockchain',
    title: 'Blockchain Backend Developer',
    type: 'Full-time',
    publishedAt: 'Mar 01, 2022',
    shortDescription:
      'Spirit Labs is a software development company that is born out of a Hong Kong-based startup incubator. We work with startups and entrepreneurs to develop game-changing applications, software, services, and platforms in different industries such as fintech, marketplaces, telecom, healthcare, lifestyles, etc.',
    level: '',
    reportTo: 'Team Leader',
    salary: 'Negotiable',
    location:
      'District 4, Ho Chi Minh City, Vietnam Mon-Fri (8.30 AM - 5.30 PM)',
    responsibilities: [
      {
        id: 1,
        text: 'Design and development of backend services for Blockchain Wallets and Transaction Processing systems;',
      },
      {
        id: 2,
        text: 'Integration with Blockchain Network nodes;',
      },
      {
        id: 3,
        text: 'Working closely with Front End and DevOps teams.',
      },
    ],
    skills: [
      {
        id: 1,
        text: '2 or more years’ experience in backend development',
      },
      {
        id: 2,
        text: 'Node.js and Express.js development',
      },
      {
        id: 3,
        text: 'Experience in SQL development',
      },
      {
        id: 4,
        text: 'Experience in Shell scripting',
      },
      {
        id: 5,
        text: 'Solidity Ethereum smart contract development experience would be a plus',
      },
      {
        id: 6,
        text: 'Interests in blockchain, cryptocurrencies, trading, and security',
      },
      {
        id: 7,
        text: 'Ability to work collaboratively with team members',
      },
      {
        id: 8,
        text: 'Attention to detail, with the ability to work independently in an ambiguous and fast-paced environment',
      },
      {
        id: 9,
        text: 'Good planning, coordination, and communication skills in English',
      },
    ],
    benefits: [
      {
        id: 1,
        text: '13th-month salary',
      },
      {
        id: 2,
        text: 'Social insurance contributions following your gross salary (100%)',
      },
      {
        id: 3,
        text: 'Premium Private Health Insurance (PVI)',
      },
      {
        id: 4,
        text: 'Company trip once a year',
      },
      {
        id: 5,
        text: 'Company activities (English Club, workshop, etc.)',
      },
      {
        id: 6,
        text: 'Yearly salary review',
      },
      {
        id: 7,
        text: 'Other welfare is in accordance with Vietnam labor regulations',
      },
    ],
  },
  {
    id: '1 - Backend NodeJS Developer',
    title: 'Backend NodeJS Developer',
    type: 'Full-time',
    publishedAt: 'Mar 01, 2022',
    shortDescription:
      'Spirit Labs is a software development company that is born out of a Hong Kong-based startup incubator. We work with startups and entrepreneurs to develop game-changing applications, software, services, and platforms in different industries such as fintech, marketplaces, telecom, healthcare, lifestyles, etc.',
    level: 'Senior',
    reportTo: 'Team Leader',
    salary: 'Negotiable',
    location:
      'District 4, Ho Chi Minh City, Vietnam Mon-Fri (8.30 AM - 5.30 PM)',
    responsibilities: [
      {
        id: 1,
        text: 'Be involved and participate in the overall application lifecycle',
      },
      {
        id: 2,
        text: 'Compile and analyze data, processes, and codes to troubleshoot problems and identify areas for improvement',
      },
      {
        id: 3,
        text: 'Mainly focus on coding and debugging',
      },
      {
        id: 4,
        text: 'Define and communicate technical and design requirements',
      },
      {
        id: 5,
        text: 'Provide training, help, and support to other team members',
      },
      {
        id: 6,
        text: 'Build high-quality reusable code that can be used in the future',
      },
      {
        id: 7,
        text: 'Develop functional and sustainable web applications with clean codes',
      },
      {
        id: 8,
        text: 'Troubleshoot and debug applications',
      },
      {
        id: 9,
        text: 'Learn about new technologies and new programming languages, stay up to date with current best practices, and follow new and emerging technologies',
      },
      {
        id: 10,
        text: 'Manage cutting-edge technologies to improve applications',
      },
      {
        id: 11,
        text: 'Collaborate with the frontend developers and other team members to establish objectives and design more functional, cohesive codes to enhance the user experience',
      },
      {
        id: 12,
        text: 'Participate in continuing education and training to remain current on best practices, and better assist other team members',
      },
      {
        id: 13,
        text: 'Take lead on projects, as needed',
      },
    ],
    skills: [
      {
        id: 1,
        text: '2+ years of experience with or proficiency with NodeJS, Typescript',
      },
      {
        id: 2,
        text: '1+ year of experience with Agile/Scrum methodologies',
      },
      {
        id: 3,
        text: 'Ability to build high performing applications that process large amounts of hourly requests',
      },
      {
        id: 4,
        text: 'Ability to work in a fast-paced and agile development environment',
      },
      {
        id: 5,
        text: 'Experience building fast and efficient web applications',
      },
      {
        id: 6,
        text: 'Experience with integrating REST API, RPC(gRPC) to components',
      },
      {
        id: 7,
        text: 'Have a good knowledge of RDBMS and have experience with MYSQL',
      },
      {
        id: 8,
        text: 'Have experience with Redis or other caching system is a plus',
      },
      {
        id: 9,
        text: 'Experience working with the web applications',
      },
      {
        id: 10,
        text: 'Understand robust app server architecture, and scalable backend design',
      },
      {
        id: 11,
        text: 'Experience scaling an application to handle an increasing user base or having the ambition to put your scaling ideas into practice is a plus',
      },
      {
        id: 12,
        text: 'Good knowledge of design, analytics, development, coding, testing and application programming',
      },
      {
        id: 13,
        text: 'A fast learner who can pick up new technologies quickly',
      },
    ],
    benefits: [
      {
        id: 1,
        text: '13th-month salary',
      },
      {
        id: 2,
        text: 'Social insurance contributions following your gross salary (100%)',
      },
      {
        id: 3,
        text: 'Premium Private Health Insurance (PVI)',
      },
      {
        id: 4,
        text: 'Company trip once a year',
      },
      {
        id: 5,
        text: 'Company activities (English Club, workshop, etc.)',
      },
      {
        id: 6,
        text: 'Yearly salary review',
      },
      {
        id: 7,
        text: 'Other welfare is in accordance with Vietnam labor regulations',
      },
    ],
  },
  {
    id: '2 - Junior Business Analyst',
    title: 'Junior Business Analyst',
    type: 'Full-time',
    publishedAt: 'Mar 01, 2022',
    shortDescription:
      'Spirit Labs is a software development company that is born out of a Hong Kong-based startup incubator. We work with startups and entrepreneurs to develop game-changing applications, software, services, and platforms in different industries such as fintech, marketplaces, telecom, healthcare, lifestyles, etc.',
    level: 'Junior',
    reportTo: 'Project Manager and Senior Business Analyst',
    salary: 'Negotiable',
    location:
      'District 4, Ho Chi Minh City, Vietnam Mon-Fri (8.30 AM - 5.30 PM)',
    responsibilities: [
      {
        id: 1,
        text: 'The mission of a BA is to work on the overall understanding of the project’s business objectives and requirements and to ensure the development team gets the correct understanding of the project and requirements.',
      },
      {
        id: 2,
        text: 'Collaborate with Project Manager and/or Senior Business Analyst to:',
        subs: [
          {
            id: 1,
            text: 'Gather business and system requirements from clients',
          },
          {
            id: 2,
            text: 'Assist and consult clients in defining requirements and proposing ideas contributing values to the client projects',
          },
          {
            id: 3,
            text: 'Engage with multiple project stakeholders',
          },
          {
            id: 4,
            text: 'Develop robust and thorough analysis and visualizations of the end-user’s journey flows',
          },
          {
            id: 5,
            text: 'Write “Agile user stories” and work with clients on acceptance criteria',
          },
          {
            id: 6,
            text: 'Work with the Project team (Developers, QA/ QC) to review and transfer requirements through the development life cycle',
          },
          {
            id: 7,
            text: 'Identify any potential quality issues per defined process and escalate potential quality issues immediately to management',
          },
          {
            id: 8,
            text: 'Support PM on the change management for the project from requirement clarifications to change approval to change implementation',
          },
        ],
      },
    ],
    skills: [
      {
        id: 1,
        text: '0-2 years of experience',
      },
      {
        id: 2,
        text: 'Experienced with any collaborative interface design tools is a plus',
      },
      {
        id: 3,
        text: 'Knowledge of BPMN 2.0',
      },
      {
        id: 4,
        text: 'Basic knowledge of UML, database, SQL, life-cycle software',
      },
      {
        id: 5,
        text: 'Communication and interpersonal skills',
      },
      {
        id: 6,
        text: 'Time management and organizational skills',
      },
      {
        id: 7,
        text: 'Problem-solving skills',
      },
      {
        id: 8,
        text: 'Exceptional analytical and conceptual thinking skills',
      },
      {
        id: 9,
        text: 'An interest in, and understanding of, project management techniques and computing systems',
      },
    ],
    benefits: [
      {
        id: 1,
        text: '13th-month salary',
      },
      {
        id: 2,
        text: 'Social insurance contributions following your gross salary (100%)',
      },
      {
        id: 3,
        text: 'Premium Private Health Insurance (PVI)',
      },
      {
        id: 4,
        text: 'Company trip once a year',
      },
      {
        id: 5,
        text: 'Company activities (English Club, workshop, etc.)',
      },
      {
        id: 6,
        text: 'Yearly salary review',
      },
      {
        id: 7,
        text: 'Other welfare is in accordance with Vietnam labor regulations',
      },
    ],
  },
  // {
  //   id: '3 - HR Executive',
  //   title: 'HR Executive',
  //   type: 'Full-time',
  //   publishedAt: 'Mar 01, 2022',
  //   shortDescription:
  //     'Spirit Labs is a software development company that is born out of a Hong Kong-based startup incubator. We work with startups and entrepreneurs to develop game-changing applications, software, services, and platforms in different industries such as fintech, marketplaces, telecom, healthcare, lifestyles, etc.',
  //   level: '',
  //   reportTo: 'HR Director',
  //   salary: 'Negotiable',
  //   location:
  //     'District 4, Ho Chi Minh City, Vietnam Mon-Fri (8.30 AM - 5.30 PM)',
  //   responsibilities: [
  //     {
  //       id: 1,
  //       text: 'Shape the company culture into a unique culture',
  //     },
  //     {
  //       id: 2,
  //       text: 'Work with Board of Directors on the HR development plan and recruitment plan',
  //     },
  //     {
  //       id: 3,
  //       text: 'Involve in the hiring process',
  //     },
  //     {
  //       id: 4,
  //       text: 'Design and propose the working policies',
  //     },
  //     {
  //       id: 5,
  //       text: 'Setup and implement an HRM system',
  //     },
  //   ],
  //   skills: [
  //     {
  //       id: 1,
  //       text: 'Be curious about human and organizational psychology, sociology, and management principles',
  //     },
  //     {
  //       id: 2,
  //       text: 'Entrepreneurial and business mindsets',
  //     },
  //     {
  //       id: 3,
  //       text: 'Awareness of the way how to influence people',
  //     },
  //     {
  //       id: 4,
  //       text: 'Ability to work in a fast-paced and agile development environment',
  //     },
  //     {
  //       id: 5,
  //       text: 'Fast learner',
  //     },
  //     {
  //       id: 6,
  //       text: 'Detailed-oriented',
  //     },
  //     {
  //       id: 7,
  //       text: 'Systemic thinking',
  //     },
  //     {
  //       id: 8,
  //       text: 'Fluent English speaking is a plus',
  //     },
  //     {
  //       id: 9,
  //       text: 'Integrity, Trust, and Respect',
  //     },
  //   ],
  //   benefits: [
  //     {
  //       id: 1,
  //       text: '13th-month salary',
  //     },
  //     {
  //       id: 2,
  //       text: 'Social insurance contributions following your gross salary (100%)',
  //     },
  //     {
  //       id: 3,
  //       text: 'Premium Private Health Insurance (PVI)',
  //     },
  //     {
  //       id: 4,
  //       text: 'Company trip once a year',
  //     },
  //     {
  //       id: 5,
  //       text: 'Company activities (English Club, workshop, etc.)',
  //     },
  //     {
  //       id: 6,
  //       text: 'Yearly salary review',
  //     },
  //     {
  //       id: 7,
  //       text: 'Other welfare is in accordance with Vietnam labor regulations',
  //     },
  //   ],
  // },
  // {
  //   id: '4 - Marketing Executive',
  //   title: 'Marketing Executive',
  //   type: 'Full-time',
  //   publishedAt: 'Mar 01, 2022',
  //   shortDescription:
  //     'Spirit Labs is a software development company that is born out of a Hong Kong-based startup incubator. We work with startups and entrepreneurs to develop game-changing applications, software, services, and platforms in different industries such as fintech, marketplaces, telecom, healthcare, lifestyles, etc.',
  //   level: '',
  //   reportTo: 'Business Development Manager',
  //   salary: 'Negotiable',
  //   location:
  //     'District 4, Ho Chi Minh City, Vietnam Mon-Fri (8.30 AM - 5.30 PM)',
  //   responsibilities: [
  //     {
  //       id: 1,
  //       text: 'Branding',
  //       subs: [
  //         {
  //           id: 1,
  //           text: 'Understand company’s vision, mission, corporate culture, business services',
  //         },
  //         {
  //           id: 2,
  //           text: 'Work with the business development team on defining and designing the company’s brand identity and story',
  //         },
  //         {
  //           id: 3,
  //           text: 'Work with graphic designers to realize the defined brand’s identity and story into corporate identity kit, marketing activities',
  //         },
  //         {
  //           id: 4,
  //           text: 'Identify and define the competitive advantages of the current business solutions and services',
  //         },
  //         {
  //           id: 5,
  //           text: 'Conduct market research in the tech industry to position the company brand through the brand story and the business’s competitive advantages',
  //         },
  //       ],
  //     },
  //     {
  //       id: 2,
  //       text: 'Brand Awareness:',
  //       subs: [
  //         {
  //           id: 1,
  //           text: 'Define, propose and execute the brand awareness strategy',
  //         },
  //         {
  //           id: 2,
  //           text: 'Manage the contents for the company website in line with the branding',
  //         },
  //         {
  //           id: 3,
  //           text: 'Develop and maintain the company social profile pages',
  //         },
  //         {
  //           id: 4,
  //           text: 'Define and communicate the referral programs',
  //         },
  //       ],
  //     },
  //     {
  //       id: 3,
  //       text: 'Plan & Communications:',
  //       subs: [
  //         {
  //           id: 1,
  //           text: 'Develop & execute the multi-channel marketing plan',
  //         },
  //         {
  //           id: 2,
  //           text: 'Develop and enrich the lead database',
  //         },
  //         {
  //           id: 3,
  //           text: 'Define, propose and execute the communication plan to leads',
  //         },
  //         {
  //           id: 4,
  //           text: 'Organize business development workshops/ meetings with prospective clients',
  //         },
  //         {
  //           id: 5,
  //           text: 'Engage with talent communities to attract talents through the brand stories and company culture',
  //         },
  //       ],
  //     },
  //   ],
  //   skills: [
  //     {
  //       id: 1,
  //       text: '0-3 years of experience',
  //     },
  //     {
  //       id: 2,
  //       text: 'Being able to think strategically, customer-centric and have marketing insights',
  //     },
  //     {
  //       id: 3,
  //       text: 'Desire to work with branding',
  //     },
  //     {
  //       id: 4,
  //       text: 'Knowledge about digital marketing, brand positioning, product management',
  //     },
  //     {
  //       id: 5,
  //       text: 'You are detail-oriented, entrepreneurial, innovative, responsible and like challenges',
  //     },
  //   ],
  //   benefits: [
  //     {
  //       id: 1,
  //       text: '13th-month salary',
  //     },
  //     {
  //       id: 2,
  //       text: 'Social insurance contributions following your gross salary (100%)',
  //     },
  //     {
  //       id: 3,
  //       text: 'Premium Private Health Insurance (PVI)',
  //     },
  //     {
  //       id: 4,
  //       text: 'Company trip once a year',
  //     },
  //     {
  //       id: 5,
  //       text: 'Company activities (English Club, workshop, etc.)',
  //     },
  //     {
  //       id: 6,
  //       text: 'Yearly salary review',
  //     },
  //     {
  //       id: 7,
  //       text: 'Other welfare is in accordance with Vietnam labor regulations',
  //     },
  //   ],
  // },
  {
    id: '5 - Project Manager',
    title: 'Project Manager',
    type: 'Full-time',
    publishedAt: 'Mar 01, 2022',
    shortDescription:
      'Spirit Labs is a software development company that is born out of a Hong Kong-based startup incubator. We work with startups and entrepreneurs to develop game-changing applications, software, services, and platforms in different industries such as fintech, marketplaces, telecom, healthcare, lifestyles, etc.',
    level: '',
    reportTo: 'CEO',
    salary: 'Negotiable',
    location:
      'District 4, Ho Chi Minh City, Vietnam Mon-Fri (8.30 AM - 5.30 PM)',
    responsibilities: [
      {
        id: 1,
        text: 'Organize the project development operations around the agile activities (daily Scrums, sprint plannings, showcases, retrospectives)',
      },
      {
        id: 2,
        text: 'Work with project stakeholders on priority and timeline',
      },
      {
        id: 3,
        text: 'Work with BA / Project Coordinators / Designer in analyzing the business flows and logics',
      },
      {
        id: 4,
        text: 'Ensure the project team has a strong understanding of requirements and business logics',
      },
      {
        id: 5,
        text: 'Provide constructive and timeline feedback to delivery team member and stakeholders',
      },
      {
        id: 6,
        text: 'Provide the conflict resolutions between delivery team and all stakeholders',
      },
      {
        id: 7,
        text: 'Ensure the project team’s delivery based on the agreed development timeline and plan',
      },
      {
        id: 8,
        text: 'Visual representation and management of work in the sprint',
      },
      {
        id: 9,
        text: 'Management of master Story List',
      },
      {
        id: 10,
        text: 'Ensure stories are up to date and ready to place in the sprint',
      },
    ],
    skills: [
      {
        id: 1,
        text: '2+ years of experience with a project management role in the IT field',
      },
      {
        id: 2,
        text: 'Excellent English written and verbal communication skills',
      },
      {
        id: 3,
        text: 'Outstanding leadership, organizational, and time management skills',
      },
      {
        id: 4,
        text: 'Strong ability influence on cross functional teams',
      },
      {
        id: 5,
        text: 'Understanding of MSP ticketing and Alert Management Systems',
      },
      {
        id: 6,
        text: 'Thorough understanding of project management fundamentals',
      },
      {
        id: 7,
        text: 'Understanding of software development life cycle',
      },
      {
        id: 8,
        text: 'Strong people skills are a plus',
      },
      {
        id: 9,
        text: 'Multi-tasking and time-management skills, with the ability to prioritize tasks',
      },
      {
        id: 10,
        text: 'Good command of spoken and written English',
      },
      {
        id: 11,
        text: 'Good knowledge of at least 3 of the following areas is a plus',
        subs: [
          {
            id: 1,
            text: 'Mobile: iOS, Android',
          },
          {
            id: 2,
            text: 'Web: API, HTML, CSS, Angular JS',
          },
          {
            id: 3,
            text: 'Quality Assurance',
          },
          {
            id: 4,
            text: 'Web security: OWASP',
          },
        ],
      },
    ],
    benefits: [
      {
        id: 1,
        text: '13th-month salary',
      },
      {
        id: 2,
        text: 'Social insurance contributions following your gross salary (100%)',
      },
      {
        id: 3,
        text: 'Premium Private Health Insurance (PVI)',
      },
      {
        id: 4,
        text: 'Company trip once a year',
      },
      {
        id: 5,
        text: 'Company activities (English Club, workshop, etc.)',
      },
      {
        id: 6,
        text: 'Yearly salary review',
      },
      {
        id: 7,
        text: 'Other welfare is in accordance with Vietnam labor regulations',
      },
    ],
  },
  {
    id: '6 - React Native Developer',
    title: 'React Native Developer',
    type: 'Full-time',
    publishedAt: 'Mar 01, 2022',
    shortDescription:
      'Spirit Labs is a software development company that is born out of a Hong Kong-based startup incubator. We work with startups and entrepreneurs to develop game-changing applications, software, services, and platforms in different industries such as fintech, marketplaces, telecom, healthcare, lifestyles, etc. \nWe are looking for Frontend Developers joining our development team in Ho Chi Minh city in collaboration with the development team in Hong Kong. If you are interested in working with technology experts, join us.',
    level: '',
    reportTo: 'Team Leader',
    salary: 'Negotiable',
    location:
      'District 4, Ho Chi Minh City, Vietnam Mon-Fri (8.30 AM - 5.30 PM)',
    responsibilities: [
      {
        id: 1,
        text: 'Create modern, high performance applications with ReactJS, React Native, Mobx, Redux',
      },
      {
        id: 2,
        text: 'Ensure cross-browser, cross-device performance, compatibility and responsiveness',
      },
      {
        id: 3,
        text: 'Optimize application for maximum speed and scalability',
      },
      {
        id: 4,
        text: 'Ability to work independently as well as in a team',
      },
      {
        id: 5,
        text: 'Keep up with the latest development techniques and security practices',
      },
      {
        id: 6,
        text: 'Work closely with UX Designer to ensure optimal implementation of User Interfaces',
      },
    ],
    skills: [
      {
        id: 1,
        text: '1-3 years of experience with or proficiency with web services, CSS3, AJAX, JavaScript, HTML5',
      },
      {
        id: 2,
        text: 'Experienced with React, JSX, ES6',
      },
      {
        id: 3,
        text: 'At least 1 years of experience with Agile/Scrum methodologies is a plus',
      },
      {
        id: 4,
        text: 'Experience building fast and efficient web applications',
      },
      {
        id: 5,
        text: 'Experience integrating REST API to front end components',
      },
      {
        id: 6,
        text: 'Ability to build high performing applications is a plus',
      },
      {
        id: 7,
        text: 'Ability to work in a fast-paced and agile development environment',
      },
      {
        id: 8,
        text: 'Attention to detail, with the ability to work independently in an ambiguous and fast-paced environment',
      },
      {
        id: 9,
        text: 'Good planning, coordination and communication skills',
      },
    ],
    benefits: [
      {
        id: 1,
        text: '13th-month salary',
      },
      {
        id: 2,
        text: 'Social insurance contributions following your gross salary (100%)',
      },
      {
        id: 3,
        text: 'Premium Private Health Insurance (PVI)',
      },
      {
        id: 4,
        text: 'Company trip once a year',
      },
      {
        id: 5,
        text: 'Company activities (English Club, workshop, etc.)',
      },
      {
        id: 6,
        text: 'Yearly salary review',
      },
      {
        id: 7,
        text: 'Other welfare is in accordance with Vietnam labor regulations',
      },
    ],
  },
  {
    id: '7 - Frontend Developer',
    title: 'Frontend Developer',
    type: 'Full-time',
    publishedAt: 'Mar 01, 2022',
    shortDescription:
      'Spirit Labs is a software development company that is born out of a Hong Kong-based startup incubator. We work with startups and entrepreneurs to develop game-changing applications, software, services, and platforms in different industries such as fintech, marketplaces, telecom, healthcare, lifestyles, etc. \nWe are looking for Frontend Developers joining our development team in Ho Chi Minh city in collaboration with the development team in Hong Kong. If you are interested in working with technology experts, join us.',
    level: '',
    reportTo: 'Team Leader',
    salary: 'Negotiable',
    location:
      'District 4, Ho Chi Minh City, Vietnam Mon-Fri (8.30 AM - 5.30 PM)',
    responsibilities: [
      {
        id: 1,
        text: 'Create modern, high performance applications with ReactJS, React Native, Mobx, Redux',
      },
      {
        id: 2,
        text: 'Ensure cross-browser, cross-device performance, compatibility and responsiveness',
      },
      {
        id: 3,
        text: 'Optimize application for maximum speed and scalability',
      },
      {
        id: 4,
        text: 'Ability to work independently as well as in a team',
      },
      {
        id: 5,
        text: 'Keep up with the latest development techniques and security practices',
      },
      {
        id: 6,
        text: 'Work closely with UX Designer to ensure optimal implementation of User Interfaces',
      },
    ],
    skills: [
      {
        id: 1,
        text: '1-3 years of experience with or proficiency with web services, CSS3, AJAX, JavaScript, HTML5',
      },
      {
        id: 2,
        text: 'Experienced with React or Angular, JQuery, Bootstrap',
      },
      {
        id: 3,
        text: 'At least 1 years of experience with Agile/Scrum methodologies is a plus',
      },
      {
        id: 4,
        text: 'Experience building fast and efficient web applications',
      },
      {
        id: 5,
        text: 'Experience integrating REST API to front end components',
      },
      {
        id: 6,
        text: 'Ability to build high performing applications is a plus',
      },
      {
        id: 7,
        text: 'Ability to work in a fast-paced and agile development environment',
      },
      {
        id: 8,
        text: 'Attention to detail, with the ability to work independently in an ambiguous and fast-paced environment',
      },
      {
        id: 9,
        text: 'Good planning, coordination and communication skills',
      },
    ],
    benefits: [
      {
        id: 1,
        text: '13th-month salary',
      },
      {
        id: 2,
        text: 'Social insurance contributions following your gross salary (100%)',
      },
      {
        id: 3,
        text: 'Premium Private Health Insurance (PVI)',
      },
      {
        id: 4,
        text: 'Company trip once a year',
      },
      {
        id: 5,
        text: 'Company activities (English Club, workshop, etc.)',
      },
      {
        id: 6,
        text: 'Yearly salary review',
      },
      {
        id: 7,
        text: 'Other welfare is in accordance with Vietnam labor regulations',
      },
    ],
  },
  {
    id: '8 - Tester',
    title: 'Tester',
    type: 'Full-time',
    publishedAt: 'Mar 01, 2022',
    shortDescription:
      'Spirit Labs is a software development company that is born out of a Hong Kong-based startup incubator. We work with startups and entrepreneurs to develop game-changing applications, software, services, and platforms in different industries such as fintech, marketplaces, telecom, healthcare, lifestyles, etc.',
    level: '',
    reportTo: 'Project Manager',
    salary: 'Negotiable',
    location:
      'District 4, Ho Chi Minh City, Vietnam Mon-Fri (8.30 AM - 5.30 PM)',
    responsibilities: [
      {
        id: 1,
        text: 'Review project/product requirements and preparing test cases',
      },
      {
        id: 2,
        text: 'Execute manual tests on product/application usability',
      },
      {
        id: 3,
        text: 'Analyze test results on database impacts, errors or bugs, usability and track product testing metrics',
      },
      {
        id: 4,
        text: 'Prepare reports on all aspects related to the testing carried out and reporting to the development team',
      },
      {
        id: 5,
        text: 'Report defects and ensure the development team in their understanding on the reported defects',
      },
      {
        id: 6,
        text: 'Interact with clients/stakeholders to understand requirements',
      },
      {
        id: 7,
        text: 'Participate in design reviews and providing input on requirements, product design, and potential problems',
      },
      {
        id: 8,
        text: 'Prepare test cases, test scripts and test data',
      },
      {
        id: 9,
        text: 'Prepare and present test reports',
      },
      {
        id: 10,
        text: 'Participate in client meetings to ensure that engagement work products and deliverables are of the highest quality',
      },
    ],
    skills: [
      {
        id: 1,
        text: '0 - 3 years of experience with application testing',
      },
      {
        id: 2,
        text: 'Sound knowledge on Blackbox and Whitebox testing',
      },
      {
        id: 3,
        text: 'Understanding on manual, performance and automation testing – expertise in manual test tools and frameworks',
      },
      {
        id: 4,
        text: 'Must have functional and basic non-functional testing experience',
      },
      {
        id: 5,
        text: 'Log defects and track to closure, working with development teams to establish defect validity, cause and retesting as required',
      },
      {
        id: 6,
        text: 'Strong problem solving and troubleshooting skills',
      },
      {
        id: 7,
        text: 'Detailed-oriented, systemic thinking, patient',
      },
      {
        id: 8,
        text: 'Fluent English speaking is a plus',
      },
      {
        id: 9,
        text: 'Understanding of non-functional testing and experience in performance testing',
      },
      {
        id: 10,
        text: 'Ability to work in a fast-paced and agile development environment',
      },
    ],
    benefits: [
      {
        id: 1,
        text: '13th-month salary',
      },
      {
        id: 2,
        text: 'Social insurance contributions following your gross salary (100%)',
      },
      {
        id: 3,
        text: 'Premium Private Health Insurance (PVI)',
      },
      {
        id: 4,
        text: 'Company trip once a year',
      },
      {
        id: 5,
        text: 'Company activities (English Club, workshop, etc.)',
      },
      {
        id: 6,
        text: 'Yearly salary review',
      },
      {
        id: 7,
        text: 'Other welfare is in accordance with Vietnam labor regulations',
      },
    ],
  },
  {
    id: '8 - Junior Graphic Designer',
    title: 'Junior Graphic Designer',
    type: 'Full-time',
    publishedAt: 'Mar 01, 2022',
    shortDescription:
      'Spirit Labs is a software development company that is born out of a Hong Kong-based startup incubator. We work with startups and entrepreneurs to develop game-changing applications, software, services, and platforms in different industries such as fintech, marketplaces, telecom, healthcare, lifestyles, etc.',
    level: '',
    reportTo: 'Team Lead',
    salary: 'Negotiable',
    location:
      'District 4, Ho Chi Minh City, Vietnam Mon-Fri (8.30 AM - 5.30 PM)',
    responsibilities: [
      {
        id: 1,
        text: 'Create and design various materials for printing, social media và digital assets',
      },
      {
        id: 2,
        text: 'Ensure projects are completed with high quality and on schedule',
      },
      {
        id: 3,
        text: 'Establish creative direction for the company as well as brand identity, brand guidelines',
      },
      {
        id: 4,
        text: 'Prioritize and manage multiple projects within design specifications and budget restrictions',
      },
      {
        id: 5,
        text: 'Contributing to team efforts by accomplishing tasks as needed',
      },
    ],
    skills: [
      {
        id: 1,
        text: '0-1 year Graphic design experience for digital products or services',
      },
      {
        id: 2,
        text: 'Knowledge of layouts, graphic fundamentals, typography, print, and the web',
      },
      {
        id: 3,
        text: 'Familiarity with design software and technologies (such as InDesign, Illustrator, Dreamweaver, Photoshop)',
      },
      {
        id: 4,
        text: 'Understanding of marketing, production, website design, corporate identity, product packaging, advertisements, and multimedia design',
      },
      {
        id: 5,
        text: 'A team player but can work independently too',
      },
      {
        id: 6,
        text: 'Excellent written and verbal communication skills',
      },
      {
        id: 7,
        text: 'Multi-tasking and time-management skills, with the ability to prioritize tasks.',
      },
    ],
    benefits: [
      {
        id: 1,
        text: '13th-month salary',
      },
      {
        id: 2,
        text: 'Social insurance contributions following your gross salary (100%)',
      },
      {
        id: 3,
        text: 'Premium Private Health Insurance (PVI)',
      },
      {
        id: 4,
        text: 'Company trip once a year',
      },
      {
        id: 5,
        text: 'Company activities (English Club, workshop, etc.)',
      },
      {
        id: 6,
        text: 'Yearly salary review',
      },
      {
        id: 7,
        text: 'Other welfare is in accordance with Vietnam labor regulations',
      },
    ],
  },
  {
    id: '8 - DevOps',
    title: 'DevOps',
    type: 'Full-time',
    publishedAt: 'Mar 01, 2022',
    shortDescription:
      'Spirit Labs is a software development company that is born out of a Hong Kong-based startup incubator. We work with startups and entrepreneurs to develop game-changing applications, software, services, and platforms in different industries such as fintech, marketplaces, telecom, healthcare, lifestyles, etc.',
    level: '',
    salary: 'Negotiable',
    location:
      'District 4, Ho Chi Minh City, Vietnam Mon-Fri (8.30 AM - 5.30 PM)',
    responsibilities: [
      {
        id: 1,
        text: 'Installation and maintenance of Blockchains and internal systems',
      },
      {
        id: 2,
        text: 'Container Orchestration (k8s, local)',
      },
      {
        id: 3,
        text: 'DevOps support for developers',
      },
      {
        id: 4,
        text: 'Managing and monitoring all installed systems and infrastructure',
      },
      {
        id: 5,
        text: 'Design and implementation of new systems and applications',
      },
    ],
    skills: [
      {
        id: 1,
        text: '3+ years of experience is preferred, but we welcome fresh graduates with good IT knowledge to also apply',
      },
      {
        id: 2,
        text: 'Diploma/Degree in Information Technology or related disciplines',
      },
      {
        id: 3,
        text: 'Knowledge of Linux (preferable Debian/Ubuntu based distributions)',
      },
      {
        id: 4,
        text: 'You love the console and are not afraid to read man pages',
      },
      {
        id: 5,
        text: 'Basic networking knowledge (DNS, HTTP, TCP/IP)',
      },
      {
        id: 6,
        text: 'Able to communicate in English',
      },
      {
        id: 7,
        text: 'Self-motivated, willing to learn',
      },
      {
        id: 7,
        text: 'Required onsite working in Hong Kong / Singapore 1-2 weeks, twice a year',
      },
    ],
    benefits: [
      {
        id: 1,
        text: '13th-month salary',
      },
      {
        id: 2,
        text: 'Social insurance contributions following your gross salary (100%)',
      },
      {
        id: 3,
        text: 'Premium Private Health Insurance (PVI)',
      },
      {
        id: 4,
        text: 'Company trip once a year',
      },
      {
        id: 5,
        text: 'Company activities (English Club, workshop, etc.)',
      },
      {
        id: 6,
        text: 'Yearly salary review',
      },
      {
        id: 7,
        text: 'Other welfare is in accordance with Vietnam labor regulations',
      },
    ],
  },
];
