import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { breakPoints } from 'constant/breakPoints';
import { useEffect, useRef } from 'react';

function FlowerBanner(): JSX.Element {
  const isFirst = useRef(true);

  useEffect(() => {
    if (!isFirst.current) return;

    const ele = document.getElementById('banner-hero');
    const elements: any = document.getElementsByClassName(
      'banner-hero-animate',
    );

    if (ele && elements) {
      ele.style.display = 'none';

      setTimeout(() => {
        ele.style.display = 'initial';

        for (let i = 0; i < elements.length; i++) {
          elements[i].beginElement();
        }
      }, 3_000);
    }

    isFirst.current = false;
  }, []);

  return (
    <Wrap className="text-center">
      <ImageRotate>
        <img src="images/logo.png" alt="Spirit labs" />
      </ImageRotate>
      <MainTitle>
        <p className="font-normal">DIGITAL SOLUTION PARTNER</p>
        <h4>
          <span className="font-normal">Meet</span> <strong>Spirit Labs</strong>
        </h4>
      </MainTitle>

      <Bottom>
        <p className="font-medium">Access to the digital world</p>
      </Bottom>
    </Wrap>
  );
}

export default FlowerBanner;

const aniOut = keyframes`
  0%{
    transform: translateX(0) skewX(0);
  }
  100%{
    transform: translateX(-150%) skewX(-20deg);
  }
`;
const Wrap = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: var(--purple-darken);
  animation: ${aniOut} 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3s forwards;

  @media screen and (max-width: ${breakPoints.tabletS}px) {
    padding-bottom: 90px;
  }
`;

const aniRotate = keyframes`
  0% {
    transform: rotate(0);
  }
  100%{
    transform: rotate(360deg);
  }
`;
const ImageRotate = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin: 0 auto;
    width: 100%;
    height: auto;
    animation: ${aniRotate} 30s ease;
  }

  @media screen and (max-width: ${breakPoints.tabletS}px) {
    height: calc(100% - 90px);
  }

  @media screen and (min-width: ${breakPoints.tabletL}px) {
    img {
      width: auto;
      height: 100%;
    }
  }
`;

const aniText = keyframes`
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;
const MainTitle = styled.div`
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 28px;
    letter-spacing: 0.05em;
    color: var(--gray);
    transform: translateY(20px);
    opacity: 0;
    animation: ${aniText} 1.5s ease forwards;
  }

  h4 {
    font-size: 96px;
    margin: 8px 0 0;
    transform: translateY(20px);
    opacity: 0;
    animation: ${aniText} 1.5s ease 1.4s forwards;

    span {
      color: var(--gray);
    }
  }

  @media screen and (max-width: ${breakPoints.tabletS}px) {
    p {
      font-size: 16px;
    }

    h4 {
      font-size: 40px;
    }
  }
`;

const Bottom = styled.div`
  font-size: 18px;
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    margin-bottom: 24px;
  }
`;
