import styled from '@emotion/styled';
import { breakPoints } from 'constant/breakPoints';

import { FlowerIntroZoomIn } from 'styles/styled';

function IntroFlower(): JSX.Element {
  return (
    <Wrap>
      <svg
        width="400"
        height="400"
        viewBox="0 0 400 400"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        transform="scale(0.5)"
      >
        <mask
          id="mask0_1730_5806"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="400"
          height="400"
        >
          <path
            d="M200.218 165.797C200.192 165.733 200.17 165.67 200.143 165.607C200.095 165.633 200.047 165.655 200.002 165.681C200.073 165.726 200.147 165.759 200.218 165.797Z"
            fill="url(#paint0_radial_1730_5806)"
          />
          <path
            d="M233.714 148.43C235.826 137.16 236.609 125.673 236.039 114.268C235.67 106.843 234.723 99.3797 233.229 92.0882C228.177 67.4118 216.858 44.4531 200.494 25.6935L199.998 25.1234L199.503 25.6935C183.872 43.6074 172.807 65.4558 167.464 88.9398C162.538 85.1991 157.441 81.7452 152.307 78.6639L152.079 78.5261C159.52 51.0628 173.947 26.0549 193.821 6.18119L200.002 0L206.183 6.18119C226.728 26.7255 241.404 52.6314 248.625 81.0932C250.257 87.5277 251.512 94.1597 252.358 100.803C254.199 115.252 254.098 129.924 252.056 144.421L251.717 144.477C245.718 145.446 239.668 146.772 233.714 148.43Z"
            fill="url(#paint1_radial_1730_5806)"
          />
          <path
            d="M275.283 197.537C270.376 193.99 265.156 190.648 259.761 187.6C269.244 181.114 277.936 173.524 285.611 165.033C290.619 159.493 295.239 153.524 299.345 147.29C313.172 126.299 321.376 102.103 323.064 77.3226L323.116 76.57L322.364 76.6222C299.054 78.2094 276.129 85.6164 255.998 98.0607C255.134 91.9317 253.956 85.8995 252.488 80.1133L252.432 79.886C276.859 66.0929 304.408 58.8014 332.144 58.8014H340.885V67.5423C340.885 96.507 332.993 125.118 318.064 150.29C314.677 156.001 310.88 161.59 306.778 166.907C297.829 178.502 287.329 188.871 275.566 197.731L275.283 197.537Z"
            fill="url(#paint2_radial_1730_5806)"
          />
          <path
            d="M193.821 393.823C173.231 373.234 158.54 347.264 151.334 318.728C149.702 312.267 148.45 305.613 147.612 298.955C145.805 284.592 145.917 270.005 147.944 255.601L148.283 255.545C154.241 254.587 160.295 253.257 166.286 251.592C164.207 262.691 163.417 274.003 163.939 285.225C164.286 292.695 165.213 300.211 166.7 307.565C171.715 332.38 183.06 355.461 199.506 374.31L200.002 374.88L200.497 374.31C215.87 356.687 226.847 335.219 232.283 312.159C237.235 315.87 242.339 319.287 247.473 322.323L247.667 322.439C240.156 349.507 225.819 374.18 206.183 393.819L200.002 400L193.821 393.823Z"
            fill="url(#paint3_radial_1730_5806)"
          />
          <path
            d="M58.0307 341.653V332.912C58.0307 303.698 66.0562 274.867 81.2355 249.531C84.6559 243.823 88.4749 238.25 92.592 232.963C101.441 221.603 111.784 211.431 123.345 202.724L123.625 202.925C128.554 206.483 133.77 209.821 139.147 212.862C129.843 219.229 121.3 226.651 113.74 234.934C108.718 240.44 104.071 246.387 99.9357 252.605C85.8817 273.731 77.5506 298.12 75.8479 323.136L75.7957 323.888L76.5483 323.836C100.293 322.219 123.588 314.581 143.983 301.731C144.836 307.856 146.014 313.896 147.478 319.697L147.541 319.95C122.835 334.146 94.9169 341.649 66.7716 341.649H58.0307V341.653Z"
            fill="url(#paint4_radial_1730_5806)"
          />
          <path
            d="M79.3725 147.704C65.4079 123.158 58.0307 95.4526 58.0307 67.5497V58.8088H66.7716C96.031 58.8088 124.899 66.8567 150.261 82.0805C155.984 85.5157 161.569 89.3496 166.86 93.4816C178.168 102.308 188.291 112.614 196.962 124.123L196.76 124.402C193.213 129.317 189.875 134.533 186.823 139.924C180.523 130.714 173.19 122.253 165.016 114.76C159.501 109.704 153.533 105.021 147.273 100.844C126.103 86.7117 101.646 78.336 76.5446 76.6259L75.792 76.5737L75.8441 77.3263C77.4425 100.795 84.9353 123.855 97.5251 144.086C91.3885 144.969 85.3601 146.169 79.5961 147.648L79.3725 147.704Z"
            fill="url(#paint5_radial_1730_5806)"
          />
          <path
            d="M77.6922 247.695C50.5603 240.191 25.839 225.846 6.18124 206.181L0 200L6.18124 193.819C26.6028 173.397 52.3338 158.762 80.5909 151.501C87.0032 149.854 93.6278 148.576 100.286 147.704C107.261 146.795 114.377 146.333 121.438 146.333C129.091 146.333 136.826 146.877 144.427 147.946L144.482 148.285C145.451 154.284 146.781 160.335 148.432 166.288C139.557 164.623 130.499 163.781 121.497 163.781C118.941 163.781 116.352 163.848 113.803 163.986C106.382 164.377 98.9223 165.346 91.6344 166.862C67.133 171.956 44.3306 183.245 25.6937 199.504L25.1237 200L25.6937 200.496C43.3768 215.921 64.9236 226.916 88.0762 232.333C84.3354 237.27 80.889 242.363 77.8226 247.482L77.6922 247.695Z"
            fill="url(#paint6_radial_1730_5806)"
          />
          <path
            d="M332.137 341.653C303.216 341.653 274.642 333.788 249.504 318.903C243.807 315.531 238.226 311.749 232.913 307.659C221.262 298.69 210.848 288.154 201.95 276.339L202.152 276.06C205.699 271.153 209.037 265.933 212.089 260.538C218.646 270.121 226.325 278.895 234.925 286.63C240.446 291.596 246.393 296.183 252.604 300.259C273.543 314.004 297.668 322.156 322.364 323.84L323.116 323.892L323.064 323.139C321.458 299.551 313.902 276.387 301.197 256.085C307.471 255.236 313.522 254.088 319.197 252.672L319.45 252.609C333.471 277.196 340.881 304.954 340.881 332.916V341.657H332.137V341.653Z"
            fill="url(#paint7_radial_1730_5806)"
          />
          <path
            d="M278.38 253.712C270.95 253.712 263.282 253.16 255.585 252.076L255.529 251.741C254.56 245.739 253.23 239.68 251.572 233.715C260.458 235.384 269.52 236.226 278.514 236.226C280.589 236.226 282.687 236.182 284.758 236.092C292.221 235.772 299.744 234.863 307.114 233.399C332.103 228.428 355.338 217.053 374.31 200.499L374.88 200.004L374.31 199.508C356.456 183.934 334.689 172.887 311.29 167.522C315.013 162.566 318.437 157.458 321.477 152.328L321.604 152.115C349.015 159.567 373.978 173.979 393.819 193.823L400 200.004L393.819 206.185C373.095 226.908 346.955 241.648 318.228 248.805C311.909 250.381 305.247 251.603 298.421 252.438C291.539 253.28 284.799 253.708 278.387 253.712H278.38Z"
            fill="url(#paint8_radial_1730_5806)"
          />
          <path
            d="M192.261 181.954C190.733 181.108 189.153 180.277 187.432 179.413C184.865 178.12 182.145 176.864 179.116 175.579L178.281 173.459C178.184 173.217 178.099 172.971 178.009 172.725C177.935 172.52 177.86 172.315 177.782 172.11C175.602 166.387 173.777 160.452 172.353 154.446C176.187 155.668 179.421 156.812 182.458 158.023L183.054 158.261L183.307 157.673C185.208 153.265 187.417 148.742 189.884 144.226C193.118 138.302 196.776 132.504 200.759 126.986C209.202 115.298 219.225 104.728 230.559 95.5552C231.755 101.952 232.523 108.394 232.836 114.721L232.932 116.658C224.396 124.743 216.881 133.79 210.596 143.555C206.393 150.079 202.704 156.972 199.627 164.044C199.556 164.204 199.485 164.364 199.411 164.524C199.318 164.729 199.224 164.934 199.139 165.139C199.012 165.434 198.893 165.732 198.774 166.026L198.546 166.581C197.183 169.886 195.964 173.161 194.921 176.313C194.329 178.101 193.785 179.856 193.304 181.529L193.054 182.397L192.261 181.954Z"
            fill="url(#paint9_radial_1730_5806)"
          />
          <path
            d="M170.919 304.568C169.73 298.122 168.981 291.643 168.687 285.294L168.594 283.293C176.675 275.48 183.829 266.773 189.858 257.41C194.06 250.886 197.753 243.993 200.827 236.925L201.072 236.363C201.274 235.904 201.475 235.446 201.669 234.98L201.829 234.578C203.356 230.893 204.765 227.026 206.017 223.087L206.21 222.484C206.527 221.493 206.855 220.468 207.149 219.443L207.664 217.663L209.284 218.557C210.234 219.082 211.211 219.585 212.153 220.073L212.66 220.334C215.738 221.929 219.024 223.471 222.425 224.924L223.249 227.026C225.641 233.106 227.634 239.5 229.192 246.072C225.201 244.79 221.632 243.512 218.312 242.167L217.712 241.925L217.459 242.521C215.447 247.264 213.126 252.052 210.566 256.743C207.336 262.663 203.677 268.461 199.69 273.982C191.516 285.302 181.839 295.585 170.919 304.568Z"
            fill="url(#paint10_radial_1730_5806)"
          />
          <path
            d="M154.965 228.672C156.183 224.85 157.327 221.612 158.541 218.568L158.78 217.972L158.188 217.718C153.448 215.71 148.668 213.389 143.97 210.826C138.049 207.595 132.252 203.936 126.73 199.95C115.187 191.611 104.728 181.727 95.6299 170.553C102.031 169.338 108.469 168.552 114.785 168.217L116.718 168.116C124.718 176.51 133.66 183.91 143.299 190.117C149.823 194.32 156.716 198.009 163.787 201.086L164.391 201.351C164.812 201.537 165.237 201.723 165.662 201.902C165.934 202.018 166.206 202.122 166.481 202.23C166.686 202.308 166.891 202.39 167.092 202.472C170.375 203.802 173.683 205.01 176.932 206.06C178.535 206.578 179.887 207.007 181.273 207.405L182.965 207.893L182.484 208.769C181.683 210.218 180.919 211.697 180.282 212.953C178.862 215.736 177.495 218.672 176.116 221.91L173.993 222.748C173.773 222.834 173.549 222.916 173.322 222.998C173.128 223.069 172.935 223.14 172.741 223.214C166.984 225.405 161.015 227.238 154.965 228.672Z"
            fill="url(#paint11_radial_1730_5806)"
          />
          <path
            d="M122.386 253.964C116.983 253.964 111.282 253.651 105.422 253.028C109.136 247.648 113.179 242.551 117.452 237.868L118.779 236.415C119.979 236.445 121.19 236.459 122.386 236.459C132.773 236.459 143.135 235.357 153.187 233.181C160.773 231.538 168.259 229.276 175.431 226.452C175.58 226.392 175.729 226.336 175.878 226.284C176.061 226.217 176.243 226.146 176.426 226.075L177.354 225.699C180.819 224.28 184.261 222.707 187.589 221.023C189.299 220.159 190.826 219.354 192.261 218.56L192.939 218.188L193.3 219.447C193.729 220.93 194.228 222.54 194.828 224.369C195.774 227.253 196.84 230.163 198.088 233.251L197.179 235.342C197.127 235.465 197.067 235.588 197.011 235.707C196.959 235.822 196.903 235.934 196.851 236.05C194.306 241.813 191.307 247.499 187.928 252.969C186.039 249.306 184.466 246.005 183.144 242.905L182.89 242.316L182.294 242.551C177.775 244.343 173.065 245.964 168.3 247.365C161.802 249.273 155.114 250.789 148.418 251.87C139.812 253.259 131.048 253.964 122.386 253.964Z"
            fill="url(#paint12_radial_1730_5806)"
          />
          <path
            d="M283.359 232.454C275.456 224.227 266.641 216.962 257.154 210.852C250.63 206.649 243.738 202.957 236.67 199.883C236.506 199.812 236.345 199.737 236.181 199.663C235.954 199.562 235.731 199.458 235.503 199.361L235.101 199.186C231.476 197.662 227.642 196.239 223.703 194.961C222.373 194.529 220.797 194.022 219.188 193.56L218.226 193.284L219.076 191.745C219.836 190.371 220.566 188.947 221.192 187.725C222.683 184.812 224.113 181.745 225.455 178.601L227.575 177.766L227.649 177.74C233.681 175.37 240.045 173.384 246.58 171.838C245.288 175.881 244.013 179.45 242.694 182.714L242.452 183.31L243.045 183.564C247.445 185.46 251.972 187.673 256.491 190.14C262.408 193.37 268.209 197.029 273.731 201.015C285.192 209.294 295.591 219.104 304.652 230.193C298.199 231.362 291.716 232.093 285.367 232.365L283.359 232.454Z"
            fill="url(#paint13_radial_1730_5806)"
          />
          <path
            d="M165.371 197.439C159.398 194.838 153.474 191.731 147.748 188.191C151.567 186.224 155.043 184.581 158.344 183.183L158.94 182.93L158.698 182.334C156.85 177.729 155.162 172.833 153.679 167.788C151.775 161.309 150.262 154.621 149.182 147.907C146.954 134.092 146.525 119.945 147.908 105.839C153.314 109.583 158.418 113.652 163.098 117.944L164.585 119.307C164.391 130.552 165.49 141.774 167.849 152.669C169.492 160.255 171.757 167.74 174.578 174.912C174.663 175.128 174.745 175.344 174.827 175.56C174.946 175.877 175.069 176.198 175.196 176.51L175.42 177.058H175.423C176.921 180.687 178.646 184.402 180.55 188.098L180.658 188.307C181.239 189.435 181.839 190.602 182.469 191.742L183.169 193.009L181.262 193.56C180.125 193.888 178.985 194.253 177.886 194.603L177.45 194.741C174.157 195.792 170.796 197.003 167.461 198.348L165.371 197.439Z"
            fill="url(#paint14_radial_1730_5806)"
          />
          <path
            d="M207.149 181.522C206.795 180.296 206.404 179.07 206.024 177.885L205.927 177.584C204.903 174.379 203.733 171.153 202.358 167.725L203.271 165.624C203.312 165.534 203.353 165.441 203.397 165.352C203.442 165.255 203.491 165.158 203.532 165.057C206.091 159.252 209.113 153.515 212.522 147.993C214.411 151.652 215.98 154.953 217.306 158.053L217.56 158.645L218.159 158.403C223.003 156.439 228.081 154.673 233.246 153.157C239.717 151.257 246.405 149.74 253.127 148.656C261.629 147.285 270.344 146.588 279.025 146.588C284.454 146.588 289.957 146.86 295.393 147.4C291.712 152.788 287.688 157.904 283.429 162.613L282.111 164.074C281.134 164.055 280.158 164.044 279.182 164.044C268.794 164.044 258.425 165.147 248.361 167.326C240.772 168.969 233.287 171.235 226.118 174.055L225.626 174.249C225.09 174.458 224.553 174.67 224.02 174.89C223.841 174.964 223.663 175.039 223.484 175.117L223.115 175.277C219.642 176.734 216.177 178.355 212.813 180.091L212.5 180.255C211.442 180.799 210.35 181.362 209.284 181.95L207.548 182.908L207.149 181.522Z"
            fill="url(#paint15_radial_1730_5806)"
          />
          <path
            d="M253.529 295.805C248.16 292.15 243.059 288.156 238.354 283.923L236.923 282.638C237.24 270.901 236.155 259.202 233.704 247.864C232.061 240.271 229.795 232.782 226.971 225.602C226.885 225.386 226.803 225.17 226.725 224.954C226.617 224.671 226.513 224.384 226.397 224.105C226.267 223.788 226.129 223.471 225.991 223.158C225.891 222.931 225.79 222.7 225.693 222.473C224.326 219.224 222.947 216.236 221.479 213.333C220.622 211.638 219.836 210.147 219.076 208.773L218.443 207.625L219.188 207.409C220.745 206.958 222.396 206.448 224.233 205.844C227.005 204.935 229.87 203.877 232.988 202.621L235.082 203.534C235.369 203.657 235.652 203.791 235.932 203.922C236.152 204.026 236.372 204.126 236.595 204.227C242.121 206.712 247.538 209.585 252.713 212.782C249.211 214.592 246.163 216.046 243.197 217.327L242.609 217.581L242.847 218.177C244.695 222.782 246.383 227.678 247.866 232.722C249.77 239.205 251.286 245.904 252.37 252.626C254.658 266.821 255.046 281.341 253.529 295.805Z"
            fill="url(#paint16_radial_1730_5806)"
          />
        </mask>
        <g mask="url(#mask0_1730_5806)">
          <path
            d="M200.218 165.797C200.192 165.733 200.17 165.67 200.143 165.607C200.095 165.633 200.047 165.655 200.002 165.681C200.073 165.726 200.147 165.759 200.218 165.797Z"
            fill="url(#paint17_radial_1730_5806)"
          />
          <path
            className="zoom-from-origin"
            d="M233.714 148.43C235.826 137.16 236.609 125.673 236.039 114.268C235.67 106.843 234.723 99.3797 233.229 92.0882C228.177 67.4118 216.858 44.4531 200.494 25.6935L199.998 25.1234L199.503 25.6935C183.872 43.6074 172.807 65.4558 167.464 88.9398C162.538 85.1991 157.441 81.7452 152.307 78.6639L152.079 78.5261C159.52 51.0628 173.947 26.0549 193.821 6.18119L200.002 0L206.183 6.18119C226.728 26.7255 241.404 52.6314 248.625 81.0932C250.257 87.5277 251.512 94.1597 252.358 100.803C254.199 115.252 254.098 129.924 252.056 144.421L251.717 144.477C245.718 145.446 239.668 146.772 233.714 148.43Z"
            fill="url(#paint18_radial_1730_5806)"
          />
          <path
            d="M275.283 197.537C270.376 193.99 265.156 190.648 259.761 187.6C269.244 181.114 277.936 173.524 285.611 165.033C290.619 159.493 295.239 153.524 299.345 147.29C313.172 126.299 321.376 102.103 323.064 77.3226L323.116 76.57L322.364 76.6222C299.054 78.2094 276.129 85.6164 255.998 98.0607C255.134 91.9317 253.956 85.8995 252.488 80.1133L252.432 79.886C276.859 66.0929 304.408 58.8014 332.144 58.8014H340.885V67.5423C340.885 96.507 332.993 125.118 318.064 150.29C314.677 156.001 310.88 161.59 306.778 166.907C297.829 178.502 287.329 188.871 275.566 197.731L275.283 197.537Z"
            fill="url(#paint19_radial_1730_5806)"
            className="zoom-from-origin"
          />
          <path
            d="M193.821 393.823C173.231 373.234 158.54 347.264 151.334 318.728C149.702 312.267 148.45 305.613 147.612 298.955C145.805 284.592 145.917 270.005 147.944 255.601L148.283 255.545C154.241 254.587 160.295 253.257 166.286 251.592C164.207 262.691 163.417 274.003 163.939 285.225C164.286 292.695 165.213 300.211 166.7 307.565C171.715 332.38 183.06 355.461 199.506 374.31L200.002 374.88L200.497 374.31C215.87 356.687 226.847 335.219 232.283 312.159C237.235 315.87 242.339 319.287 247.473 322.323L247.667 322.439C240.156 349.507 225.819 374.18 206.183 393.819L200.002 400L193.821 393.823Z"
            fill="url(#paint20_radial_1730_5806)"
            className="zoom-from-origin"
          />
          <path
            d="M58.0307 341.653V332.912C58.0307 303.698 66.0562 274.867 81.2355 249.531C84.6559 243.823 88.4749 238.25 92.592 232.963C101.441 221.603 111.784 211.431 123.345 202.724L123.625 202.925C128.554 206.483 133.77 209.821 139.147 212.862C129.843 219.229 121.3 226.651 113.74 234.934C108.718 240.44 104.071 246.387 99.9357 252.605C85.8817 273.731 77.5506 298.12 75.8479 323.136L75.7957 323.888L76.5483 323.836C100.293 322.219 123.588 314.581 143.983 301.731C144.836 307.856 146.014 313.896 147.478 319.697L147.541 319.95C122.835 334.146 94.9169 341.649 66.7716 341.649H58.0307V341.653Z"
            fill="url(#paint21_radial_1730_5806)"
            className="zoom-from-origin"
          />
          <path
            className="zoom-from-origin"
            d="M79.3725 147.704C65.4079 123.158 58.0307 95.4526 58.0307 67.5497V58.8088H66.7716C96.031 58.8088 124.899 66.8567 150.261 82.0805C155.984 85.5157 161.569 89.3496 166.86 93.4816C178.168 102.308 188.291 112.614 196.962 124.123L196.76 124.402C193.213 129.317 189.875 134.533 186.823 139.924C180.523 130.714 173.19 122.253 165.016 114.76C159.501 109.704 153.533 105.021 147.273 100.844C126.103 86.7117 101.646 78.336 76.5446 76.6259L75.792 76.5737L75.8441 77.3263C77.4425 100.795 84.9353 123.855 97.5251 144.086C91.3885 144.969 85.3601 146.169 79.5961 147.648L79.3725 147.704Z"
            fill="url(#paint22_radial_1730_5806)"
          />
          <path
            d="M77.6922 247.695C50.5603 240.191 25.839 225.846 6.18124 206.181L0 200L6.18124 193.819C26.6028 173.397 52.3338 158.762 80.5909 151.501C87.0032 149.854 93.6278 148.576 100.286 147.704C107.261 146.795 114.377 146.333 121.438 146.333C129.091 146.333 136.826 146.877 144.427 147.946L144.482 148.285C145.451 154.284 146.781 160.335 148.432 166.288C139.557 164.623 130.499 163.781 121.497 163.781C118.941 163.781 116.352 163.848 113.803 163.986C106.382 164.377 98.9223 165.346 91.6344 166.862C67.133 171.956 44.3306 183.245 25.6937 199.504L25.1237 200L25.6937 200.496C43.3768 215.921 64.9236 226.916 88.0762 232.333C84.3354 237.27 80.889 242.363 77.8226 247.482L77.6922 247.695Z"
            fill="url(#paint23_radial_1730_5806)"
            className="zoom-from-origin"
          />
          <path
            d="M332.137 341.653C303.216 341.653 274.642 333.788 249.504 318.903C243.807 315.531 238.226 311.749 232.913 307.659C221.262 298.69 210.848 288.154 201.95 276.339L202.152 276.06C205.699 271.153 209.037 265.933 212.089 260.538C218.646 270.121 226.325 278.895 234.925 286.63C240.446 291.596 246.393 296.183 252.604 300.259C273.543 314.004 297.668 322.156 322.364 323.84L323.116 323.892L323.064 323.139C321.458 299.551 313.902 276.387 301.197 256.085C307.471 255.236 313.522 254.088 319.197 252.672L319.45 252.609C333.471 277.196 340.881 304.954 340.881 332.916V341.657H332.137V341.653Z"
            fill="url(#paint24_radial_1730_5806)"
            className="zoom-from-origin"
          />
          <path
            d="M278.38 253.712C270.95 253.712 263.282 253.16 255.585 252.076L255.529 251.741C254.56 245.739 253.23 239.68 251.572 233.715C260.458 235.384 269.52 236.226 278.514 236.226C280.589 236.226 282.687 236.182 284.758 236.092C292.221 235.772 299.744 234.863 307.114 233.399C332.103 228.428 355.338 217.053 374.31 200.499L374.88 200.004L374.31 199.508C356.456 183.934 334.689 172.887 311.29 167.522C315.013 162.566 318.437 157.458 321.477 152.328L321.604 152.115C349.015 159.567 373.978 173.979 393.819 193.823L400 200.004L393.819 206.185C373.095 226.908 346.955 241.648 318.228 248.805C311.909 250.381 305.247 251.603 298.421 252.438C291.539 253.28 284.799 253.708 278.387 253.712H278.38Z"
            fill="url(#paint25_radial_1730_5806)"
            className="zoom-from-origin"
          />
          <path
            d="M192.261 181.954C190.733 181.108 189.153 180.277 187.432 179.413C184.865 178.12 182.145 176.864 179.116 175.579L178.281 173.459C178.184 173.217 178.099 172.971 178.009 172.725C177.935 172.52 177.86 172.315 177.782 172.11C175.602 166.387 173.777 160.452 172.353 154.446C176.187 155.668 179.421 156.812 182.458 158.023L183.054 158.261L183.307 157.673C185.208 153.265 187.417 148.742 189.884 144.226C193.118 138.302 196.776 132.504 200.759 126.986C209.202 115.298 219.225 104.728 230.559 95.5552C231.755 101.952 232.523 108.394 232.836 114.721L232.932 116.658C224.396 124.743 216.881 133.79 210.596 143.555C206.393 150.079 202.704 156.972 199.627 164.044C199.556 164.204 199.485 164.364 199.411 164.524C199.318 164.729 199.224 164.934 199.139 165.139C199.012 165.434 198.893 165.732 198.774 166.026L198.546 166.581C197.183 169.886 195.964 173.161 194.921 176.313C194.329 178.101 193.785 179.856 193.304 181.529L193.054 182.397L192.261 181.954Z"
            fill="url(#paint26_radial_1730_5806)"
            className="zoom-from-origin"
          />
          <path
            d="M170.919 304.568C169.73 298.122 168.981 291.643 168.687 285.294L168.594 283.293C176.675 275.48 183.829 266.773 189.858 257.41C194.06 250.886 197.753 243.993 200.827 236.925L201.072 236.363C201.274 235.904 201.475 235.446 201.669 234.98L201.829 234.578C203.356 230.893 204.765 227.026 206.017 223.087L206.21 222.484C206.527 221.493 206.855 220.468 207.149 219.443L207.664 217.663L209.284 218.557C210.234 219.082 211.211 219.585 212.153 220.073L212.66 220.334C215.738 221.929 219.024 223.471 222.425 224.924L223.249 227.026C225.641 233.106 227.634 239.5 229.192 246.072C225.201 244.79 221.632 243.512 218.312 242.167L217.712 241.925L217.459 242.521C215.447 247.264 213.126 252.052 210.566 256.743C207.336 262.663 203.677 268.461 199.69 273.982C191.516 285.302 181.839 295.585 170.919 304.568Z"
            fill="url(#paint27_radial_1730_5806)"
            className="zoom-from-origin"
          />
          <path
            d="M154.965 228.672C156.183 224.85 157.327 221.612 158.541 218.568L158.78 217.972L158.188 217.718C153.448 215.71 148.668 213.389 143.97 210.826C138.049 207.595 132.252 203.936 126.73 199.95C115.187 191.611 104.728 181.727 95.6299 170.553C102.031 169.338 108.469 168.552 114.785 168.217L116.718 168.116C124.718 176.51 133.66 183.91 143.299 190.117C149.823 194.32 156.716 198.009 163.787 201.086L164.391 201.351C164.812 201.537 165.237 201.723 165.662 201.902C165.934 202.018 166.206 202.122 166.481 202.23C166.686 202.308 166.891 202.39 167.092 202.472C170.375 203.802 173.683 205.01 176.932 206.06C178.535 206.578 179.887 207.007 181.273 207.405L182.965 207.893L182.484 208.769C181.683 210.218 180.919 211.697 180.282 212.953C178.862 215.736 177.495 218.672 176.116 221.91L173.993 222.748C173.773 222.834 173.549 222.916 173.322 222.998C173.128 223.069 172.935 223.14 172.741 223.214C166.984 225.405 161.015 227.238 154.965 228.672Z"
            fill="url(#paint28_radial_1730_5806)"
            className="zoom-from-origin"
          />
          <path
            d="M122.386 253.964C116.983 253.964 111.282 253.651 105.422 253.028C109.136 247.648 113.179 242.551 117.452 237.868L118.779 236.415C119.979 236.445 121.19 236.459 122.386 236.459C132.773 236.459 143.135 235.357 153.187 233.181C160.773 231.538 168.259 229.276 175.431 226.452C175.58 226.392 175.729 226.336 175.878 226.284C176.061 226.217 176.243 226.146 176.426 226.075L177.354 225.699C180.819 224.28 184.261 222.707 187.589 221.023C189.299 220.159 190.826 219.354 192.261 218.56L192.939 218.188L193.3 219.447C193.729 220.93 194.228 222.54 194.828 224.369C195.774 227.253 196.84 230.163 198.088 233.251L197.179 235.342C197.127 235.465 197.067 235.588 197.011 235.707C196.959 235.822 196.903 235.934 196.851 236.05C194.306 241.813 191.307 247.499 187.928 252.969C186.039 249.306 184.466 246.005 183.144 242.905L182.89 242.316L182.294 242.551C177.775 244.343 173.065 245.964 168.3 247.365C161.802 249.273 155.114 250.789 148.418 251.87C139.812 253.259 131.048 253.964 122.386 253.964Z"
            fill="url(#paint29_radial_1730_5806)"
            className="zoom-from-origin"
          />
          <path
            d="M283.359 232.454C275.456 224.227 266.641 216.962 257.154 210.852C250.63 206.649 243.738 202.957 236.67 199.883C236.506 199.812 236.345 199.737 236.181 199.663C235.954 199.562 235.731 199.458 235.503 199.361L235.101 199.186C231.476 197.662 227.642 196.239 223.703 194.961C222.373 194.529 220.797 194.022 219.188 193.56L218.226 193.284L219.076 191.745C219.836 190.371 220.566 188.947 221.192 187.725C222.683 184.812 224.113 181.745 225.455 178.601L227.575 177.766L227.649 177.74C233.681 175.37 240.045 173.384 246.58 171.838C245.288 175.881 244.013 179.45 242.694 182.714L242.452 183.31L243.045 183.564C247.445 185.46 251.972 187.673 256.491 190.14C262.408 193.37 268.209 197.029 273.731 201.015C285.192 209.294 295.591 219.104 304.652 230.193C298.199 231.362 291.716 232.093 285.367 232.365L283.359 232.454Z"
            fill="url(#paint30_radial_1730_5806)"
            className="zoom-from-origin"
          />
          <path
            d="M165.371 197.439C159.398 194.838 153.474 191.731 147.748 188.191C151.567 186.224 155.043 184.581 158.344 183.183L158.94 182.93L158.698 182.334C156.85 177.729 155.162 172.833 153.679 167.788C151.775 161.309 150.262 154.621 149.182 147.907C146.954 134.092 146.525 119.945 147.908 105.839C153.314 109.583 158.418 113.652 163.098 117.944L164.585 119.307C164.391 130.552 165.49 141.774 167.849 152.669C169.492 160.255 171.757 167.74 174.578 174.912C174.663 175.128 174.745 175.344 174.827 175.56C174.946 175.877 175.069 176.198 175.196 176.51L175.42 177.058H175.423C176.921 180.687 178.646 184.402 180.55 188.098L180.658 188.307C181.239 189.435 181.839 190.602 182.469 191.742L183.169 193.009L181.262 193.56C180.125 193.888 178.985 194.253 177.886 194.603L177.45 194.741C174.157 195.792 170.796 197.003 167.461 198.348L165.371 197.439Z"
            fill="url(#paint31_radial_1730_5806)"
            className="zoom-from-origin"
          />
          <path
            d="M207.149 181.522C206.795 180.296 206.404 179.07 206.024 177.885L205.927 177.584C204.903 174.379 203.733 171.153 202.358 167.725L203.271 165.624C203.312 165.534 203.353 165.441 203.397 165.352C203.442 165.255 203.491 165.158 203.532 165.057C206.091 159.252 209.113 153.515 212.522 147.993C214.411 151.652 215.98 154.953 217.306 158.053L217.56 158.645L218.159 158.403C223.003 156.439 228.081 154.673 233.246 153.157C239.717 151.257 246.405 149.74 253.127 148.656C261.629 147.285 270.344 146.588 279.025 146.588C284.454 146.588 289.957 146.86 295.393 147.4C291.712 152.788 287.688 157.904 283.429 162.613L282.111 164.074C281.134 164.055 280.158 164.044 279.182 164.044C268.794 164.044 258.425 165.147 248.361 167.326C240.772 168.969 233.287 171.235 226.118 174.055L225.626 174.249C225.09 174.458 224.553 174.67 224.02 174.89C223.841 174.964 223.663 175.039 223.484 175.117L223.115 175.277C219.642 176.734 216.177 178.355 212.813 180.091L212.5 180.255C211.442 180.799 210.35 181.362 209.284 181.95L207.548 182.908L207.149 181.522Z"
            fill="url(#paint32_radial_1730_5806)"
            className="zoom-from-origin"
          />
          <path
            d="M253.529 295.805C248.16 292.15 243.059 288.156 238.354 283.923L236.923 282.638C237.24 270.901 236.155 259.202 233.704 247.864C232.061 240.271 229.795 232.782 226.971 225.602C226.885 225.386 226.803 225.17 226.725 224.954C226.617 224.671 226.513 224.384 226.397 224.105C226.267 223.788 226.129 223.471 225.991 223.158C225.891 222.931 225.79 222.7 225.693 222.473C224.326 219.224 222.947 216.236 221.479 213.333C220.622 211.638 219.836 210.147 219.076 208.773L218.443 207.625L219.188 207.409C220.745 206.958 222.396 206.448 224.233 205.844C227.005 204.935 229.87 203.877 232.988 202.621L235.082 203.534C235.369 203.657 235.652 203.791 235.932 203.922C236.152 204.026 236.372 204.126 236.595 204.227C242.121 206.712 247.538 209.585 252.713 212.782C249.211 214.592 246.163 216.046 243.197 217.327L242.609 217.581L242.847 218.177C244.695 222.782 246.383 227.678 247.866 232.722C249.77 239.205 251.286 245.904 252.37 252.626C254.658 266.821 255.046 281.341 253.529 295.805Z"
            fill="url(#paint33_radial_1730_5806"
            className="zoom-from-origin"
          />
        </g>
        <defs>
          <radialGradient
            id="paint0_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(199.74 211.381) rotate(51.8712) scale(226.257 226.265)"
          >
            <stop offset="0.0682245" stopColor="#E3C8FF" />
            <stop offset="0.458857" stopColor="#A93AED" />
            <stop offset="0.854697" stopColor="#7F31FF" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(199.74 211.381) rotate(51.8712) scale(226.257 226.265)"
          >
            <stop offset="0.0682245" stopColor="#E3C8FF" />
            <stop offset="0.458857" stopColor="#A93AED" />
            <stop offset="0.854697" stopColor="#7F31FF" />
          </radialGradient>
          <radialGradient
            id="paint2_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(199.74 211.381) rotate(51.8712) scale(226.257 226.265)"
          >
            <stop offset="0.0682245" stopColor="#E3C8FF" />
            <stop offset="0.458857" stopColor="#A93AED" />
            <stop offset="0.854697" stopColor="#7F31FF" />
          </radialGradient>
          <radialGradient
            id="paint3_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(199.74 211.381) rotate(51.8712) scale(226.257 226.265)"
          >
            <stop offset="0.0682245" stopColor="#E3C8FF" />
            <stop offset="0.458857" stopColor="#A93AED" />
            <stop offset="0.854697" stopColor="#7F31FF" />
          </radialGradient>
          <radialGradient
            id="paint4_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(199.74 211.381) rotate(51.8712) scale(226.257 226.265)"
          >
            <stop offset="0.0682245" stopColor="#E3C8FF" />
            <stop offset="0.458857" stopColor="#A93AED" />
            <stop offset="0.854697" stopColor="#7F31FF" />
          </radialGradient>
          <radialGradient
            id="paint5_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(199.74 211.381) rotate(51.8712) scale(226.257 226.265)"
          >
            <stop offset="0.0682245" stopColor="#E3C8FF" />
            <stop offset="0.458857" stopColor="#A93AED" />
            <stop offset="0.854697" stopColor="#7F31FF" />
          </radialGradient>
          <radialGradient
            id="paint6_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(199.74 211.381) rotate(51.8712) scale(226.257 226.265)"
          >
            <stop offset="0.0682245" stopColor="#E3C8FF" />
            <stop offset="0.458857" stopColor="#A93AED" />
            <stop offset="0.854697" stopColor="#7F31FF" />
          </radialGradient>
          <radialGradient
            id="paint7_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(199.74 211.381) rotate(51.8712) scale(226.257 226.265)"
          >
            <stop offset="0.0682245" stopColor="#E3C8FF" />
            <stop offset="0.458857" stopColor="#A93AED" />
            <stop offset="0.854697" stopColor="#7F31FF" />
          </radialGradient>
          <radialGradient
            id="paint8_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(199.74 211.381) rotate(51.8712) scale(226.257 226.265)"
          >
            <stop offset="0.0682245" stopColor="#E3C8FF" />
            <stop offset="0.458857" stopColor="#A93AED" />
            <stop offset="0.854697" stopColor="#7F31FF" />
          </radialGradient>
          <radialGradient
            id="paint9_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(200.005 206.008) rotate(51.87) scale(118.229 118.234)"
          >
            <stop offset="0.34375" stopColor="white" />
            <stop offset="0.796875" stopColor="#B658FF" />
          </radialGradient>
          <radialGradient
            id="paint10_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(200.005 206.008) rotate(51.87) scale(118.229 118.234)"
          >
            <stop offset="0.34375" stopColor="white" />
            <stop offset="0.796875" stopColor="#B658FF" />
          </radialGradient>
          <radialGradient
            id="paint11_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(200.005 206.008) rotate(51.87) scale(118.229 118.234)"
          >
            <stop offset="0.34375" stopColor="white" />
            <stop offset="0.796875" stopColor="#B658FF" />
          </radialGradient>
          <radialGradient
            id="paint12_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(200.005 206.008) rotate(51.87) scale(118.229 118.234)"
          >
            <stop offset="0.34375" stopColor="white" />
            <stop offset="0.796875" stopColor="#B658FF" />
          </radialGradient>
          <radialGradient
            id="paint13_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(200.005 206.008) rotate(51.87) scale(118.229 118.234)"
          >
            <stop offset="0.34375" stopColor="white" />
            <stop offset="0.796875" stopColor="#B658FF" />
          </radialGradient>
          <radialGradient
            id="paint14_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(200.005 206.008) rotate(51.87) scale(118.229 118.234)"
          >
            <stop offset="0.34375" stopColor="white" />
            <stop offset="0.796875" stopColor="#B658FF" />
          </radialGradient>
          <radialGradient
            id="paint15_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(200.005 206.008) rotate(51.87) scale(118.229 118.234)"
          >
            <stop offset="0.34375" stopColor="white" />
            <stop offset="0.796875" stopColor="#B658FF" />
          </radialGradient>
          <radialGradient
            id="paint16_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(200.005 206.008) rotate(51.87) scale(118.229 118.234)"
          >
            <stop offset="0.34375" stopColor="white" />
            <stop offset="0.796875" stopColor="#B658FF" />
          </radialGradient>
          <radialGradient
            id="paint17_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(199.74 211.381) rotate(51.8712) scale(226.257 226.265)"
          >
            <stop offset="0.0682245" stopColor="#E3C8FF" />
            <stop offset="0.458857" stopColor="#A93AED" />
            <stop offset="0.854697" stopColor="#7F31FF" />
          </radialGradient>
          <radialGradient
            id="paint18_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(199.74 211.381) rotate(51.8712) scale(226.257 226.265)"
          >
            <stop offset="0.0682245" stopColor="#E3C8FF" />
            <stop offset="0.458857" stopColor="#A93AED" />
            <stop offset="0.854697" stopColor="#7F31FF" />
          </radialGradient>
          <radialGradient
            id="paint19_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(199.74 211.381) rotate(51.8712) scale(226.257 226.265)"
          >
            <stop offset="0.0682245" stopColor="#E3C8FF" />
            <stop offset="0.458857" stopColor="#A93AED" />
            <stop offset="0.854697" stopColor="#7F31FF" />
          </radialGradient>
          <radialGradient
            id="paint20_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(199.74 211.381) rotate(51.8712) scale(226.257 226.265)"
          >
            <stop offset="0.0682245" stopColor="#E3C8FF" />
            <stop offset="0.458857" stopColor="#A93AED" />
            <stop offset="0.854697" stopColor="#7F31FF" />
          </radialGradient>
          <radialGradient
            id="paint21_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(199.74 211.381) rotate(51.8712) scale(226.257 226.265)"
          >
            <stop offset="0.0682245" stopColor="#E3C8FF" />
            <stop offset="0.458857" stopColor="#A93AED" />
            <stop offset="0.854697" stopColor="#7F31FF" />
          </radialGradient>
          <radialGradient
            id="paint22_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(199.74 211.381) rotate(51.8712) scale(226.257 226.265)"
          >
            <stop offset="0.0682245" stopColor="#E3C8FF" />
            <stop offset="0.458857" stopColor="#A93AED" />
            <stop offset="0.854697" stopColor="#7F31FF" />
          </radialGradient>
          <radialGradient
            id="paint23_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(199.74 211.381) rotate(51.8712) scale(226.257 226.265)"
          >
            <stop offset="0.0682245" stopColor="#E3C8FF" />
            <stop offset="0.458857" stopColor="#A93AED" />
            <stop offset="0.854697" stopColor="#7F31FF" />
          </radialGradient>
          <radialGradient
            id="paint24_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(199.74 211.381) rotate(51.8712) scale(226.257 226.265)"
          >
            <stop offset="0.0682245" stopColor="#E3C8FF" />
            <stop offset="0.458857" stopColor="#A93AED" />
            <stop offset="0.854697" stopColor="#7F31FF" />
          </radialGradient>
          <radialGradient
            id="paint25_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(199.74 211.381) rotate(51.8712) scale(226.257 226.265)"
          >
            <stop offset="0.0682245" stopColor="#E3C8FF" />
            <stop offset="0.458857" stopColor="#A93AED" />
            <stop offset="0.854697" stopColor="#7F31FF" />
          </radialGradient>
          <radialGradient
            id="paint26_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(200.005 206.008) rotate(51.87) scale(118.229 118.234)"
          >
            <stop offset="0.34375" stopColor="white" />
            <stop offset="0.796875" stopColor="#B658FF" />
          </radialGradient>
          <radialGradient
            id="paint27_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(200.005 206.008) rotate(51.87) scale(118.229 118.234)"
          >
            <stop offset="0.34375" stopColor="white" />
            <stop offset="0.796875" stopColor="#B658FF" />
          </radialGradient>
          <radialGradient
            id="paint28_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(200.005 206.008) rotate(51.87) scale(118.229 118.234)"
          >
            <stop offset="0.34375" stopColor="white" />
            <stop offset="0.796875" stopColor="#B658FF" />
          </radialGradient>
          <radialGradient
            id="paint29_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(200.005 206.008) rotate(51.87) scale(118.229 118.234)"
          >
            <stop offset="0.34375" stopColor="white" />
            <stop offset="0.796875" stopColor="#B658FF" />
          </radialGradient>
          <radialGradient
            id="paint30_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(200.005 206.008) rotate(51.87) scale(118.229 118.234)"
          >
            <stop offset="0.34375" stopColor="white" />
            <stop offset="0.796875" stopColor="#B658FF" />
          </radialGradient>
          <radialGradient
            id="paint31_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(200.005 206.008) rotate(51.87) scale(118.229 118.234)"
          >
            <stop offset="0.34375" stopColor="white" />
            <stop offset="0.796875" stopColor="#B658FF" />
          </radialGradient>
          <radialGradient
            id="paint32_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(200.005 206.008) rotate(51.87) scale(118.229 118.234)"
          >
            <stop offset="0.34375" stopColor="white" />
            <stop offset="0.796875" stopColor="#B658FF" />
          </radialGradient>
          <radialGradient
            id="paint33_radial_1730_5806"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(200.005 206.008) rotate(51.87) scale(118.229 118.234)"
          >
            <stop offset="0.34375" stopColor="white" />
            <stop offset="0.796875" stopColor="#B658FF" />
          </radialGradient>
        </defs>
      </svg>

      <div className="intro-flower-mash" />

      <PercentCounter>
        <div />
      </PercentCounter>
    </Wrap>
  );
}

export default IntroFlower;

const Wrap = styled.div`
  overflow: hidden;
  position: fixed;
  inset: 0;
  z-index: 9999;
  min-height: 100vh;
  background-color: var(--purple-darken);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .zoom-from-origin {
    transform-origin: center;
    ${FlowerIntroZoomIn}
  }

  .intro-flower-mash {
    &::before {
      content: '';
      background: var(--purple-darken);
      transform-origin: center center;
      transform: translate(-50%, -50%) scale(0);
      width: 100vw;
      height: 100vw;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 100;
      animation: zoom 1s ease 3.5s forwards;

      @keyframes zoom {
        0% {
          transform: translate(-50%, -50%) scale(0.3);
          opacity: 1;
        }
        100% {
          transform: translate(-50%, -50%) scale(2);
          opacity: 1;
        }
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: -1;
    background: radial-gradient(
      52.33% 52.33% at 50.03% 47.67%,
      rgba(163, 54, 217, 0.3) 0%,
      rgba(21, 16, 39, 0) 50%
    );
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    background-color: var(--purple-darken);
  }

  @media screen and (max-width: ${breakPoints.tabletS}px) {
    height: calc(100vh - 90px);

    &::after {
      top: calc(50% - 90px);
    }

    svg {
      width: 200px;
      height: 200px;
      transform: translateY(-90px);
    }
  }
`;

const PercentCounter = styled.div`
  position: absolute;
  top: calc(55% + 200px);
  overflow: hidden;

  div {
    background: #431659;
    height: 12px;
    width: 270px;
    border-radius: 100px;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      background: linear-gradient(
        302.05deg,
        #a336d9 16.75%,
        rgba(163, 54, 217, 0.6) 73.45%
      );
      height: 100%;
      width: 0%;
      border-radius: 100px;
      top: 0;
      left: 0;
      animation: loading 2.5s ease-in-out forwards;

      @keyframes loading {
        0% {
          width: 0%;
        }
        30% {
          width: 40%;
        }
        40% {
          width: 40%;
        }
        60% {
          width: 70%;
        }
        100% {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: ${breakPoints.tabletS}px) {
    div {
      height: 8px;
    }
  }
`;
