export const breakPoints = {
  desktopXL: 2500,
  desktopL: 1600,
  desktopXM: 1440,
  desktopM: 1200,
  desktopS: 1024,
  tabletL: 992,
  tabletM: 768,
  tabletS: 480,
};
